import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import FormContainer from "../../components/Form/Container";
import Type from "../../components/Form/FindExpert/Type";
import UserInfo from "../../components/Form/FindExpert/UserInfo";
import ThemeManual from "../../components/Form/FindExpert/ThemeManual";
import DetailedManual from "../../components/Form/FindExpert/DetailedManual";

const typeRoute = `${ROUTES.MANUALFINDSPECIALIST}/type`,
  themeRoute = `${ROUTES.MANUALFINDSPECIALIST}/theme`,
  detailedRoute = `${ROUTES.MANUALFINDSPECIALIST}/detailed`,
  userInfoRoute = `${ROUTES.MANUALFINDSPECIALIST}/user-info`;

const points = ["Формат", "Причини звернення", "Про себе"];

const FormManualFindSpecialist: React.FC = (props:any) => {
  const [page, setPage] = useState(1);
  const { onSubmit } = props;
  
  const submit = (values: any) => {
    const { user, theme: { manual }, type } = values;

    const data = {
      ...user,
      userRequest: {
        type,
        manual,
      }
    };

    window.localStorage.removeItem("userInfo");
    window.localStorage.setItem("userInfo", JSON.stringify(user));

    onSubmit(data);
  };

  return (
    <>
      <FormContainer color='orange' points={points}>
        <Redirect from={ROUTES.MANUALFINDSPECIALIST} exact to={`${ROUTES.MANUALFINDSPECIALIST}/type`} />
        {page === 1 && (
          <Route
            path={typeRoute}
            render={(props) => (
              <Type
                {...props}
                onSubmit={() => {
                  props.history.push(themeRoute);
                  setPage(page + 1);
                }}
              />
            )}
          />
        )}
        {page === 2 && (
          <Route
            path={themeRoute}
            render={(props) => (
              <ThemeManual
                {...props}
                onSubmit={() => {
                  props.history.push(detailedRoute);
                  setPage(page + 1);
                }}
                onBack={() => {
                  props.history.push(typeRoute);
                  setPage(page - 1);
                }}
              />
            )}
          />
        )}
        {page === 3 && (
          <Route
            path={detailedRoute}
            render={(props) => (
              <DetailedManual
                {...props}
                onSubmit={() => {
                  props.history.push(userInfoRoute);
                  setPage(page + 1);
                }}
                onBack={() => {
                  props.history.push(themeRoute);
                  setPage(page - 1);
                }}
              />
            )}
          />
        )}
        {page === 4 && (
          <Route
            path={userInfoRoute}
            render={(props) => (
              <UserInfo
                {...props}
                onSubmit={submit}
                onBack={() => {
                  props.history.push(detailedRoute);
                  setPage(page - 1);
                }}
              />
            )}
          />
        )}
      </FormContainer>
    </>
  );
};

export default FormManualFindSpecialist;
