import React from 'react';
import { Button } from '../../Button';

import classnames from './ActionButtons.module.scss';

interface IProps {
  onBack?(): void;
  valid?: boolean;
  end?: boolean;
}

const ActionButtons: React.FC<IProps> = ({
  onBack,
  valid,
  end,
}) => {
  return (
    <div className={classnames.container}>
      <div className={classnames.buttonsBox}>
        {onBack && (
          <Button buttonStyles={classnames.backButton} onClick={onBack}>
            <span className={classnames.buttonText}>Назад</span>
          </Button>
        )}

        <Button
          buttonStyles={classnames.submitButton}
          type='submit'
          disabled={!valid}
        >
          <span className={classnames.buttonText}>
            Далі
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ActionButtons
