import React from 'react';
import { Button } from '../Button';

import classnames from './ContactButtons.module.scss';

import { CONTACT_LINKS } from '../../constants/contactLinks';

import telegramSrc from '../../assets/images/telegram-icon.svg';
import messengerSrc from '../../assets/images/messenger.svg';
import gmailSrc from '../../assets/images/gmail.svg';

const buttons = [
  {
    title: "Telegram",
    iconSrc: telegramSrc,
    url: CONTACT_LINKS.telegram
  },
  // {
  //   title: "Messenger",
  //   iconSrc: messengerSrc,
  // },
  {
    title: "Email",
    url: CONTACT_LINKS.email,
    iconSrc: gmailSrc,
  },
];

const ContactButtons = () => {
  return (
    <div className={classnames.buttonsBox}>
      {buttons.map((element) => (
        <a href={element.url}
           target='_blank'
          className={classnames.button}
        >
          <img className={classnames.buttonIcon} src={element.iconSrc} alt="icon"/>
          <span className={classnames.buttonText}>{element.title}</span>
        </a>
      ))}
    </div>
  );
};

export default ContactButtons;