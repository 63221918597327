import React, {useContext, useEffect} from 'react';
import Popup from 'reactjs-popup';
import {Link, Redirect, RouteComponentProps} from 'react-router-dom';

import Menu from '../../components/Menu';
import Cta from '../../components/HomePage/Cta';
import Footer from '../../components/Footer';
import LoaderCirlce from '../../components/LoaderCircle';
import ImageGallery from '../../components/ImageGallery';
import ShowExpertVideoPopup from '../../components/Popups/ShowExpertVideo';
import FlexibleImage from '../../components/FlexibleImage';
import WindowInnerWidthContext from '../../contexts/WindowInnerWidth';

import classnames from './Expert.module.scss';

import badgeSrc from '../../assets/images/check_badge.svg';

import {renderPrice, getPopUpStyleGeneral} from '../../utils';

import {ROUTES} from '../../constants/routes';
import {EXPERT_PHOTO_FACE_VERTICAL_CENER, LARGE_MONITOR_CORRECTION} from '../../constants/expertPhoto';
import {SIZES} from '../../constants';
import {QUERY} from '../../constants/query';
import ChooseExpertPopup from '../../components/Popups/ChooseExpert';
import config from "../../config";


const DetailedItem: React.FC<{ title: string }> = ({title, children}) => (
    <div className={classnames.detailedItem}>
        <h2 className={classnames.title}>{title}</h2>
        {children}
    </div>
);
const getYearPluralName = (years: number): string => {
    switch (years) {
        case 1:
            return 'рік';
        case 2:
        case 3:
            return 'роки';
        default:
            return 'років';
    }
};

interface IProps {
    getExpert(expertId: string): void;

    loading: boolean;
    match: {
        params: {
            id: string;
        }
    };
    expert: {
        expertId: string;
        profile: {
            firstName: string;
            photoUrl: string;
            videoUrl: string;
            about: string;
            shortDescription: string;
        };
        education: {
            basic: string[];
            extra: string[];
        };
        experience: {
            years?: number;
            infoText: string;
            diplomas: string[];
        };
        therapy: {
            price: {
                online?: number;
                offline?: number;
            },
            themeBadges: string[];
            languages: string[];
            address: string;
        },
    };
}

const Expert: React.FC<IProps & RouteComponentProps & any> = props => {
    const {loading, expert, getExpert, match, history} = props;

    const search = history.location.search;
    const queryReg = new RegExp(QUERY.AFTER_TEST, 'ig');
    const isAfterTest = queryReg.test(search);

    useEffect(() => {
        getExpert(match && match.params.id);
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps
    const width = useContext(WindowInnerWidthContext);
    const isSmall = width <= SIZES.tablet;

    const PopUpStyleGeneral = getPopUpStyleGeneral(width);

    if (!expert) {
        return  <Redirect to={ROUTES.EXPERTS}/>
    }

    const {
        expertId,
        profile: {firstName, photoUrl, videoUrl, about, shortDescription},
        education: {basic, extra},
        experience: {years, diplomas, infoText},
        therapy: {price, themeBadges, languages, address},
    } = expert;

    let preferedCenter = 0;

    preferedCenter = width > SIZES.desktopMed - 1
        ? EXPERT_PHOTO_FACE_VERTICAL_CENER + LARGE_MONITOR_CORRECTION
        : EXPERT_PHOTO_FACE_VERTICAL_CENER;

    const itemList = ({title, list}: { title?: string; list: string[] }) => (
        <div className={classnames.itemList}>
            {title && <h4 className={classnames.listTitle}>{title}</h4>}
            <ul className={classnames.listContainer}>
                {list.map((item, index) => (
                    <li key={index} className={classnames.item}>{item}</li>
                ))}
            </ul>
        </div>
    );

    let consultationType = '';

    if (price.online && price.offline) {
        consultationType = 'Онлайн та особисто';
    } else if (price.online) {
        consultationType = 'Тільки онлайн';
    } else if (price.offline) {
        consultationType = 'Тільки особисто';
    }

    return (
        <div>
            <Menu/>
            <section className={classnames.container}>
                {loading ? (
                    <LoaderCirlce/>
                ) : (
                    <>
                        {!isSmall && <FlexibleImage
                            containerClassName={classnames.photoContainer}
                            className={classnames.photo}
                            preferedCenter={preferedCenter}
                            src={`${config.static_host}/${expertId}/${photoUrl}`}
                            alt="profile"
                        />}
                        <div className={classnames.detailedInfoContainer}>
                            <div className={classnames.mainInfo}>
                                {isAfterTest && //TODO Return back
                                    <Link to={ROUTES.FINDEXPERTRESULTS} className={classnames.link}>
                                        &#8592; Назад до результатів тесту
                                    </Link>
                                }
                                <h2 className={classnames.name}>
                                    <img src={badgeSrc} alt="badge"/>
                                    {firstName}
                                </h2>
                                <div className={classnames.prices}>
                                    <p>
                    <span className={classnames.priceCount}>
                      {renderPrice({
                          online: price.online,
                          offline: price.offline,
                      })}
                    </span>
                                    </p>
                                    <p>за сеанс</p>
                                </div>
                                <div className={classnames.bages}>
                                    {themeBadges &&
                                        themeBadges.map((item: string) => (
                                            <div className={classnames.badge} key={item}>
                                                {item}
                                            </div>
                                        ))}
                                </div>
                                {isSmall &&
                                    <FlexibleImage
                                        containerClassName={classnames.smallPhoto}
                                        preferedCenter={preferedCenter}
                                        src={`${config.static_host}/${expertId}/${photoUrl}`}
                                        alt="profile"
                                    />}
                                <div className={classnames.points}>
                                    {years && (
                                        <div className={classnames.point}>
                                            <div className={classnames.circle}/>
                                            <div className={classnames.text}>
                                                {`${years} ${getYearPluralName(years)} практики`}
                                            </div>
                                        </div>
                                    )}
                                    <div className={classnames.point}>
                                        <div className={classnames.circle}/>
                                        <div className={classnames.text}>
                                            {consultationType}
                                        </div>
                                    </div>
                                </div>
                                <p className={classnames.describe}>{shortDescription}</p>
                                {languages && (
                                    <div className={classnames.langs}>
                                        <p>Мова консультації:</p>
                                        <div className={classnames.langsBadges}>
                                            {languages.map((lang: string, index: number) => (
                                                <div key={index} className={classnames.lang}>{lang}</div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className={classnames.actionButtons}>
                                    <ShowExpertVideoPopup expertId={expertId} videoUrl={videoUrl}/>
                                    <ChooseExpertPopup expertId={expertId}/>
                                    {/* <Popup
                  trigger={
                    <Button buttonStyles={classnames.buttonAsk}>
                      <span className={classnames.buttonAskText}>
                        Задати питання
                      </span>
                    </Button>
                  }
                  position="right center"
                  modal
                  contentStyle={PopUpStyleGeneral}
                >
                  {close => (
                    <>
                      <ContactSpecialistForm />
                      <Button
                        buttonStyles={classNames(classnames.closeButton, { [classnames.closeButtonSmall]: isSmall })}
                        onClick={close}
                      />
                    </>
                  )}
                </Popup> */}
                                </div>
                            </div>
                            <div className={classnames.detailed}>
                                {about && (
                                    <>
                                        <DetailedItem title="Про себе">
                                            <div
                                                className={classnames.text}
                                                dangerouslySetInnerHTML={{__html: about}}
                                            />
                                        </DetailedItem>
                                        <hr className={classnames.line}/>
                                    </>
                                )}
                                <DetailedItem title="Досвід та освіта">
                                    {basic &&
                                        itemList({
                                            title: 'Основна освіта',
                                            list: basic,
                                        })}
                                    {extra &&
                                        itemList({
                                            title: 'Додаткова освіта',
                                            list: extra,
                                        })}
                                    <div
                                        className={classnames.text}
                                        dangerouslySetInnerHTML={{__html: infoText}}
                                    />
                                </DetailedItem>

                                <hr className={classnames.line}/>
                                {diplomas && <div className={classnames.imageGallery}>
                                    <ImageGallery
                                        images={diplomas.map((diplomaUrl: string) =>
                                            `${config.static_host}/${expertId}/diplomas/${diplomaUrl}`)}
                                    />
                                </div>}

                                <div className={classnames.button}>
                                    {isAfterTest ? <ChooseExpertPopup expertId={expertId}/> : null}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </section>
            <Cta/>
            <Footer/>
        </div>
    );
};

export default Expert;
