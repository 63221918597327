import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { compose } from "../../utils";
import { findSpecialistManual } from '../../redux/findSpecialist/actions';

import { IRootState } from '../../redux/reducer';

import { loading, successfullyRegistered, error } from '../../redux/findSpecialist/selectors'; 

import FormManualFindSpecialist from "./FormManualFindSpecialist";
import { IProps } from "./interface";

const findSpecialistValues = formValueSelector("findSpecialist");

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  signupSuccess: successfullyRegistered,
  findSpecialistValues,
});

const actions = { onSubmit: findSpecialistManual };

const reduxConect = connect(selector, actions);

const formConect = reduxForm<{}, IProps>({
  form: "findSpecialist",
});

export { FormManualFindSpecialist };

export default withRouter(
  compose(reduxConect, formConect)(FormManualFindSpecialist)
);
