import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from "redux-form";
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from "reselect";

import { compose } from '../../utils';
import { findSpecialist } from '../../redux/findSpecialist/actions';

import { IRootState } from '../../redux/reducer';

import { loading, successfullyRegistered, error } from '../../redux/findSpecialist/selectors'; 

import FormAutoFindSpecialist from './FormAutoFindSpecialist';
import { IProps } from './interface';

const values = formValueSelector("findSpecialist");

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  signupSuccess: successfullyRegistered,
  themeValues: (state:any) => values(state, 'theme'),
  detailed: (state:any) => values(state, 'detailed'),
  userSex: (state:any) => values(state, 'user.sex'),
});

const actions = { onSubmit: findSpecialist };

const reduxConect = connect(selector, actions);

const formConect = reduxForm<{}, IProps>({
  form: "findSpecialist",
  enableReinitialize: true,
});

export { FormAutoFindSpecialist };

export default withRouter(
  compose(reduxConect, formConect)
  (FormAutoFindSpecialist)
);