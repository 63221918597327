import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../Button';
import { ROUTES } from '../../../constants/routes';

import classnames from './About.module.scss';
import about_1 from '../../../assets/images/about_1.svg';
import about_2 from '../../../assets/images/about_2.svg';
import about_3 from '../../../assets/images/about_3.svg';
import about_4 from '../../../assets/images/about_4.svg';

const aboutImages = [
  {
    image: about_1,
    header: 'Онлайн та особисто',
    subtitle: 'Ви можете обрати зручний час та формат зустрічей.'
  },
  {
    image: about_2,
    header: 'Перевірені психологи',
    subtitle: 'Ми відбираємо кваліфікованих психологів після підтвердження їх кваліфікації та інтерв\'ю з нашими спеціалістами.'
  },
  {
    image: about_3,
    header: 'Гарантована анонімність та безпека',
    subtitle: 'Ваші персональні дані зберігатимуться лише в межах платформи. Спілкування з психологом — конфіденційне.'
  },
  {
    image: about_4,
    header: 'Різноманітність напрямків',
    subtitle: 'Ми запрошуємо психологів різних напрямків, кожен з яких в результаті є однаково ефективним.'
  }
];

type Props = {

};

class About extends Component<Props> {
  render() {
    return (
      <section className={classnames.about} id='about'>
        <div className={classnames.aboutContent}>
          <div className={classnames.aboutTextContainer}>
            <h1 className={classnames.aboutTitle}>Наша мета та переваги</h1>
            <div className={classnames.aboutText}>
              <p>
                Піклуватися про ментальне здоровʼя - це не лише тренд сучасності, а необхідність. Ми вважаємо психологічне здоровʼя не менш важливим за фізичне. В житті часто трапляються моменти, коли нас переслідують відчуття тривоги або ж ситуації, про які важко говорити навіть з близькими людьми. Саме в такі моменти на допомогу може прийти кваліфікований спеціаліст.
              </p>
              <br/>
              <p>
                egobalance - це платформа для пошуку психолога/психотерапевта.
              </p>
              <br />
              <p>
                Ми допоможемо вам знайти спеціаліста відповідно до вашого запиту.
              </p>
              <br />
              <p>
                Наше прагнення - зробити процес пошуку психолога якомога легшим та комфортним.
              </p>
              <br />
              <p>
                Розуміємо, як то бентежно зробити перший крок до змін у своєму житті, навіть якщо не знаєш, як це працює, спробуй, а ми будемо поруч та підтримаємо тебе на цьому шляху🧡
              </p>
            </div>
          </div>
          <div className={classnames.quotesContainer}>
            <div className={classnames.qoutesSymbol}>
              <svg
                width='29'
                height='21'
                viewBox='0 0 29 21'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  opacity='0.5'
                  d='M0.933594 13.5527C0.933594 11.4824 1.64648 9.28516 3.07227 6.96094C4.51758 4.61719 7.11523 2.47852 10.8652 0.544922L11.5684 1.86328C9.69336 3.19141 8.30664 4.30469 7.4082 5.20312C5.82617 6.82422 5.03516 8.37695 5.03516 9.86133C5.03516 10.3691 5.18164 10.7012 5.47461 10.8574C5.76758 11.0332 6.02148 11.1211 6.23633 11.1211C6.97852 11.0234 7.4668 10.9746 7.70117 10.9746C8.95117 10.9746 9.97656 11.4336 10.7773 12.3516C11.5781 13.25 11.9785 14.3438 11.9785 15.6328C11.9785 17.0781 11.5391 18.2891 10.6602 19.2656C9.78125 20.2227 8.57031 20.7012 7.02734 20.7012C5.21094 20.7012 3.73633 20.0566 2.60352 18.7676C1.49023 17.459 0.933594 15.7207 0.933594 13.5527ZM17.1055 13.5527C17.1055 11.3652 17.8672 9.10938 19.3906 6.78516C20.9141 4.46094 23.4629 2.38086 27.0371 0.544922L27.7402 1.86328C25.9824 3.09375 24.6348 4.17773 23.6973 5.11523C22.0371 6.77539 21.207 8.31836 21.207 9.74414C21.207 10.1543 21.3145 10.4863 21.5293 10.7402C21.7441 10.9941 22.0371 11.1211 22.4082 11.1211C23.1504 11.0234 23.6387 10.9746 23.873 10.9746C25.1035 10.9746 26.1191 11.4238 26.9199 12.3223C27.7402 13.2207 28.1504 14.3242 28.1504 15.6328C28.1504 17.1562 27.7012 18.3867 26.8027 19.3242C25.9043 20.2422 24.6836 20.7012 23.1406 20.7012C21.3828 20.7012 19.9375 20.0566 18.8047 18.7676C17.6719 17.459 17.1055 15.7207 17.1055 13.5527Z'
                  fill='#C4C4C4'
                />
              </svg>
            </div>
            <p className={classnames.quotesText}>
              Турбота про ментальне здоров'я - це про любов, повагу, чесне самосприйняття та час для себе.
            </p>
          </div>
        </div>
        <div className={classnames.badges}>
          {aboutImages.map((element) => (
            <div className={classnames.badge} key={element.header}>
              <div className={classnames.imageContainer}>
                <img
                  src={element.image}
                  alt='about_1'
                  className={classnames.image}
                />
              </div>
              <div className={classnames.info}>
                <h4 className={classnames.header}>{element.header}</h4>
                <p className={classnames.subtitle}>{element.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
        <Link to={ROUTES.FINDSPECIALIST} className={classnames.buttonContainer}>
          <Button buttonStyles={classnames.button}>
            <span className={classnames.buttonText}>Знайти психолога</span>
          </Button>
        </Link>
      </section>
    );
  }
}

export default About;