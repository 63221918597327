export const prefix = 'util';

export const SET_ANY_MODAL_OPEN = `${prefix}/SET_ANY_MODAL_OPEN`;

export const setAnyModalOpen = (isOpen: boolean) => ({
  type: SET_ANY_MODAL_OPEN,
  payload: {
    isOpen,
  },
});
