import React from 'react';

import classnames from './CustomCheckbox.module.scss';

const CustomCheckbox: React.FC<{
  meta: {
    error: string;
    dirty: boolean;
  };
  type: string;
  name: string;
  input: any;
  label: string;
}> = ({ input, label, type, name, meta: { dirty, error } }) => {
  return (
    <label className={classnames.box}>
      <span className={classnames.labeltext} dangerouslySetInnerHTML={{ __html: label }}></span>
      <input name={name} type={type} {...input} />
      <span className={classnames.mark}></span>
      <span
        className={`${classnames.errorMessage} ${
          dirty && error && classnames.errorMessageShow
        }`}
      >
        {error}
      </span>
    </label>
  );
};

export default CustomCheckbox;