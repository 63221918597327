const config = {
  api_port: process.env.NODE_ENV === 'production' ? '' : 9000,
  api_host: process.env.NODE_ENV === 'production' ? 'https://api.egobalance.com.ua' : 'http://localhost',
  apiPrefix: '/api/v1/',
  static_host: process.env.NODE_ENV === 'production' ? 'https://egobalance-website-static.s3.eu-central-1.amazonaws.com' : 'https://egobalance-website-static-dev.s3.eu-central-1.amazonaws.com',
  metaConfig: {
    default: {
      title: 'egobalance',
      description: 'egobalance — це сучасна платформа для пошуку психолога. Ми допоможемо вам знайти спеціаліста відповідно до вашого запиту.',
      keywords: ''
    },
    home: {
      title: 'egobalance | Платформа для пошуку психологів та психотерапевтів online',
      description: 'egobalance — це платформа для пошуку психологів online. Якщо вам потрібна консультація психолога чи консультація психотерапевта — ми допоможемо вам підібрати перевіреного спеціаліста.',
      keywords: 'консультація психолога, консультація психотерапевта, психолог online'
    },
    experts: {
      title: 'Наші спеціалісти | egobalance',
      description: 'На платформі egobalance ви можете обрати консультацію психолога онлайн або офлайн. Психологічна консультація — це перший крок до набуття психологічного здоров\'я, готові його зробити?',
      keywords: 'консультація психолога онлайн, психологічна консультація'
    }
  },
  metaReplace: {
    title: /__OG_TITLE__/g,
    description: /__OG_DESCR__/g,
    keywords: /__OG_KEYWORDS__/g,
  }
};

export default config;
