import React from 'react';
import ProgressBar from '../../components/ProgressBar';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button'; 
import ContactButtons from '../../components/ContactButtons';

import classnames from './FormSuccess.module.scss';

import logoSrc from '../../assets/images/form_success_logo.svg';

const points = ["Формат", "Причини звернення", "Про себе"];
const FormSuccess: React.FC = () => {
  return (
    <div>
      <ProgressBar points={points} color='orange' />
      <div className={classnames.container}>
        <div className={classnames.logoContainer}>
          <img src={logoSrc} alt='success logo' className={classnames.logo} />
        </div>
        <h1 className={classnames.title}>Дякуємо за ваш час!</h1>
        <p className={classnames.subtitle}>
          Ми зв'яжемося з вами найближчим часом.
        </p>
        <Link to='/' className={classnames.buttonContainer}>
          <Button buttonStyles={classnames.button}>
            <span className={classnames.buttonText}>На головну</span>
          </Button>
        </Link>
        <p className={classnames.contact}>
          Якщо у вас виникли запитання, напишіть нам!
        </p>
        <div className={classnames.contactButtons}>
          <ContactButtons />
        </div>
      </div>
    </div>
  );
};

export default FormSuccess;