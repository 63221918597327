import React from 'react';
import Card from '../Card';
import Popup from "reactjs-popup";
import classNames from "classnames";

import classnames from './Input.module.scss';

export interface IProps {
  input: any;
  label: string;
  type: string;
  placeholder?: string;
  meta: {
    touched: boolean,
    error: string,
    visited: boolean,
  };
  size?:string;
  inCard?: boolean;
  likeRadio?: boolean;
  isMulti?: boolean;
  limit?: number;
  options?: Array<{label: string, value: string, labelStyles?: string}>
  inputStyles?: string;
  labelStyles?: string;
  rows?: number;
  selectOptions?: Array<any>
}

export interface AddProps {
  input: {
    name: string,
    onChange(): void,
    value: string,
    checked: boolean,
  },
}

const InputField: React.FC<AddProps & IProps> = ({
  input,
  placeholder,
  label,
  type,
  meta,
  size,
  inCard,
  likeRadio,
  isMulti,
  options,
  limit,
  inputStyles,
  labelStyles,
  rows,
  selectOptions,
}) => {
  const { touched, error, visited } = meta;

  const isBig = size === 'big';


  if (type === 'radio') {
    const { checked } = input;
    const renderInput = (
      <>
        <input className={`${isBig && classnames.inputBig}`} type={type} {...input} />
        <div className={`${classnames.labelText} ${isBig && classnames.big} ${labelStyles && labelStyles}`}>{label}</div>
      </>
    )
    return (
      <label className={`${classnames.label} ${labelStyles && labelStyles}`}>
        {inCard ? <Card checked={checked}>{renderInput}</Card> : renderInput}
      </label>
    );
  }

  if (type === 'checkbox' && !isMulti) {
    const { checked } = input;
    const renderInput = (
      <>
        <input className={`${isBig && classnames.inputBig} ${likeRadio && classnames.likeRadio}`} type={type} {...input} />
        <div className={`${classnames.labelText} ${isBig && classnames.big}`}>{label}</div>
      </>
    )
    return (
      <label className={`${classnames.label} ${labelStyles && labelStyles}`}>
        {inCard ? <Card checked={checked}>{renderInput}</Card> : renderInput}
      </label>
    );
  }

  if (type === 'checkbox' && isMulti) {
    const { name, onChange } = input;
    const inputValue = input.value;
    const elemetLimit = limit || (options && options.length) || 10000;

    const checkboxes = options && options.map(({ label, value, labelStyles: ls }, index) => {

      const handleChange = (event: any) => {
        const arr = [...inputValue];
        if (event.target.checked) {
          if (arr.length >= elemetLimit) {
            arr.splice(elemetLimit - 1, 1, value);
          } else {
            arr.push(value);
          }
        } else {
          arr.splice(arr.indexOf(value), 1);
        }

        return onChange(arr);
      };
      const checked = inputValue.includes(value);
      const renderInput = (
        <>
          <input
            className={`${isBig && classnames.inputBig} ${likeRadio && classnames.likeRadio}`}
            name={`${name}.${index}`}
            type={type}
            value={value}
            checked={checked}
            onChange={handleChange}
          />
          <div className={`${classnames.labelText} ${isBig && classnames.big} ${ls && ls}`}>{label}</div>
        </>
      );
      return (
        <label
          className={`${classnames.label} ${labelStyles && labelStyles}`}
          key={`checkbox-${index}`}
        >
          {inCard ? <Card checked={checked}>{renderInput}</Card> : renderInput}
        </label>
      );
    });

    return (
      <div>
        <div>{checkboxes}</div>
        {touched && error && <p className="error">{error}</p>}
      </div>
    );
  }

  if (type === 'textarea'){
    return (
      <label className={`${classnames.mainLabel} ${labelStyles && labelStyles}`}>
        {label}
        <div className={classnames.textAreaContainer}>
          <textarea
            rows={rows}
            {...input}
            placeholder={placeholder || label}
            className={`${inputStyles && inputStyles} ${
              touched && error && classnames.error
            }`}
          />
         <span className={`${classnames.errorMessage} ${touched && error && classnames.errorMessageShow}`}>{error}</span>
        </div>
      </label>
    );
  }

  if (type === 'select') {
    return (
      <label
        className={`${classnames.mainLabel} ${labelStyles && labelStyles}`}
      >
        {label}
        <div className={classnames.inputBox}>
          <select
            {...input}
            className={`${inputStyles && inputStyles} ${visited && error && classnames.error}`}
          >
            <option value="">-----</option>
            {selectOptions?.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
          <span
            className={`${classnames.errorMessage} ${
             visited && error && classnames.errorMessageShow
            }`}
          >
            {error}
          </span>
        </div>
      </label>
    );
  }

  if (type === 'input-select') {
    const contentStyle = { width: '100%', padding: 0 };

    const { name, onChange, value } = input;

    return (
        <>
          <label className={classNames(classnames.mainLabel, labelStyles)} style={{position: "relative"}}>
            {label}
            <div className={classnames.inputBox}>
              <Popup {...{contentStyle}} closeOnDocumentClick={false} trigger={
                <input name={name}
                       onChange={onChange}
                       value={value}
                       placeholder={placeholder || label}
                       type={type}
                       className={`${inputStyles && inputStyles} ${touched && error && classnames.error}`}
                />
              }
              {...{ contentStyle }} arrow={false} on='click'
              >
                <div className={classnames.inputSelect__menu}>
                  {options?.map(({label, value}) => (
                      <div key={label} className={classnames.inputSelect__item} onClick={() => onChange(value)}>{label}</div>
                  ))}
                </div>
              </Popup>
              <span className={`${classnames.errorMessage} ${touched && error && classnames.errorMessageShow}`}>{error}</span>
            </div>
          </label>
        </>
    )
  }

  return (
    <label className={`${classnames.mainLabel} ${labelStyles && labelStyles}`}>
      {label}
      <div className={classnames.inputBox}>
        <input
          {...input}
          placeholder={placeholder || label}
          type={type}
          className={`${inputStyles && inputStyles} ${
            touched && error && classnames.error
          }`}
        />
        <span className={`${classnames.errorMessage} ${touched && error && classnames.errorMessageShow}`}>
          {error}
        </span>
      </div>
    </label>
  );
};

export default InputField;
