import React, { FunctionComponent, useContext } from 'react';
import classNames from 'classnames';
import Popup from 'reactjs-popup';
import ReactPlayer from 'react-player';

import classnames from './ShowExpertVideo.module.scss';

import { ReactComponent as PlayIcon } from '../../../assets/images/play.svg';
import { Button } from '../../Button';
import ChooseExpertPopup from '../ChooseExpert';
import WindowInnerWidthContext from '../../../contexts/WindowInnerWidth';

import { SIZES } from '../../../constants';
import { getPopUpStyleGeneral } from '../../../utils';
import config from "../../../config";

type Props = {
  videoUrl: string;
  expertId: string;
  setAnyModalOpen: (isOpen: boolean) => void;
  buttonClassName?: string;
  children?: JSX.Element;
};

const ShowExpertVideoPopup: FunctionComponent<Props> = ({
  buttonClassName,
  videoUrl,
  expertId,
  children,
  setAnyModalOpen,
}) => {
  const width = useContext(WindowInnerWidthContext);
  const isSmall = width <= SIZES.tablet;
  const PopUpStyleGeneral = getPopUpStyleGeneral(width);

  return <Popup
    trigger={children ? children : <Button
        buttonStyles={classNames(
          classnames.showVideoButton,
          buttonClassName,
        )}
        disabled={!videoUrl}
      >
        <PlayIcon />
        <span className={classnames.buttonChooseText}>
          Дивитись відео
        </span>
      </Button>}
    position="right center"
    modal
    contentStyle={PopUpStyleGeneral}
    onOpen={() => setAnyModalOpen(true)}
    onClose={() => setAnyModalOpen(false)}
  >
    {close => (
      <div>
        <div className={classnames.playerContainer}>
          <ReactPlayer
            className="react-player"
            url={`${config.static_host}/${expertId}/${videoUrl}`}
            width="100%"
            playing
            controls
            config={{
              file: {
                attributes: { controlsList: 'nodownload' },
              },
            }}
          />
        </div>
        <Button
          buttonStyles={classNames(
            classnames.closeButtonVideo,
            { [classnames.closeButtonSmall]: isSmall }
          )}
          onClick={close}
        />
        <div className={classnames.bottomContainer}>
          <ChooseExpertPopup expertId={expertId} />
        </div>
      </div>
    )}
  </Popup>;
};

ShowExpertVideoPopup.defaultProps = {
  buttonClassName: '',
};

export default ShowExpertVideoPopup;
