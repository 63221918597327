export const TAB_LIST = [
  {
    text: 'Перебуваю в стресі',
    id: 'E',
  },
  {
    text: 'Хочу знайти себе',
    id: 'B',
  },
  {
    text: 'Відчуваю сильну втому',
    id: 'C',
  },
  {
    text: 'Депресія',
    id: 'D',
  },
  {
    text: 'Напружені відносини у парі',
    id: 'I',
  },
  {
    text: 'Важко знайти другу половинку',
    id: 'F',
  },
  {
    text: 'Непрості стосунки з батьками',
    id: 'G',
  },
  {
    text: 'Непорозуміння з дітьми',
    id: 'H',
  },
  {
    text: 'Саморозвиток та кар\'єра',
    id: 'A',
  },
  {
    text: 'Хвилювання пов’язані з вагітністю',
    id: 'J',
  },
] as const;