import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import { DotProps, ButtonGroupProps, ArrowProps } from 'react-multi-carousel/lib/types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import 'react-multi-carousel/lib/styles.css';
import classnames from './Tabs.module.scss';

import { Button } from '../../Button';
import Badge from '../../../components/Badge';

import { renderPrice } from '../../../utils';
import { ROUTES } from '../../../constants/routes';
import { TAB_LIST } from '../../../constants/tabsList';
import config from "../../../config";

type Props = {
  getList(limit?:number, theme?:string): void;
  experts: any;
  isAnyModalOpen: boolean;
};

type State = {
  width: number;
  theme: string;
};

const CustomDots = ({ index, active, onClick, carouselState }: DotProps) => {
  if (carouselState?.totalItems === carouselState?.slidesToShow) return null;

  return (
    <button
      className={`${classnames.dot} ${active && classnames.activeDot}`}
      onClick={onClick}
    />
  );
};

class HomePageTabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      width: 0,
      theme: ''
    };
  }
  
  chooseTheme = (theme: string) => {
    this.setState({theme}, () => {
      const { getList } = this.props;
      getList(Infinity, theme);
    })
    
  }

  handleResize = () => {
    const width = window.innerWidth;
    this.setState((prevProps) => ({ width }));
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    const { getList } = this.props;
    getList(4);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { width } = this.state;
    const { experts, isAnyModalOpen } = this.props;

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1024, min: 480 },
        items: 2,
        partialVisibilityGutter: 40,
      },
      mobile: {
        breakpoint: { max: 480, min: 0 },
        items: 1,
        partialVisibilityGutter: 80,
      },
    };

    return (
      <section className={classnames.container} id='findExpert'>
        <div className={classnames.specialists}>
          <h1 className={classnames.title}>
            Допомагаємо вирішити такі питання:
          </h1>
          {width > 480 ? (
            <div className={classnames.tabs}>
              {TAB_LIST.map((tab) => (
                <Button
                  key={tab.id}
                  buttonStyles={[classnames.button, tab.id === this.state.theme ? classnames.isClicked : null].join(" ")}
                  onClick={this.chooseTheme.bind(null, tab.id)}
                >
                  <span className={classnames.buttonText}>{tab.text}</span>
                </Button>
              ))}
            </div>
          ) : (
            <div className={classnames.list}>
              <label>Оберіть зі списку</label>
              <select name='spec' id='spec' onChange={ event => this.chooseTheme(event.target.value) }>
                {TAB_LIST.map((tab) => (
                  <option className={classnames.option} value={tab.id}>
                    {tab.text}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className={classnames.people} id="experts">
            <h2 className={classnames.peopleTitle}>Наші спеціалісти:</h2>
            <Link to={ROUTES.EXPERTS} className={classnames.linkToAll}>
              Усі спеціалісти &#8594;
            </Link>
            <Carousel
              responsive={responsive}
              draggable={false}
              focusOnSelect={false}
              minimumTouchDrag={80}
              partialVisible
              slidesToSlide={1}
              swipeable={true}
              transitionDuration={500}
              showDots={false}  // https://github.com/YIZHUANG/react-multi-carousel/issues/106
              // customDot={<CustomDots />}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              containerClass={classnames.profileContainer}
              sliderClass={classNames(classnames.slider, { [classnames.sliderPopupOpen]: isAnyModalOpen })}
              itemClass={classNames(classnames.profile, { [classnames.profilePopupOpen]: isAnyModalOpen })}
            >
              {experts &&
                experts.map((expert: any, i: number) => {
                  const {
                    profile: { firstName, photoUrl, videoUrl },
                    therapy: { price },
                    expertId,
                  } = expert;
                  return (
                    <Badge
                      key={firstName + i}
                      className={classnames.tabsBadge}
                      title={firstName}
                      expertId={expertId}
                      imageUrl={`${config.static_host}/${expertId}/${photoUrl}`}
                      videoUrl={videoUrl}
                      subtitle={
                        <span>
                          <b>
                            {renderPrice({
                              online: price.online,
                              offline: price.offline,
                            })}
                          </b>{" "}
                          за сеанс
                        </span>
                      }
                      linkTitle='Детальніше &#8594;'
                    />
                  );
                })}
            </Carousel>
          </div>
        </div>
      </section>
    );
  }
}

export default HomePageTabs;
