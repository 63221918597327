import { IApi } from '../../api';

export const prefix = "findSpecialist";

export const FIND_SPECIALIST_REQUEST = `${prefix}/FIND_SPECIALIST_REQUEST`;
export const FIND_SPECIALIST_SUCCESS = `${prefix}/FIND_SPECIALIST_SUCCESS`;
export const FIND_SPECIALIST_FAILURE = `${prefix}/FIND_SPECIALIST_FAILURE`;
export const RESET_SUCCESS_STATE = `${prefix}/RESET_SUCCESS_STATE`;


export const findSpecialist = (
  data: any,
) => {
  return {
    type: [
      FIND_SPECIALIST_REQUEST,
      FIND_SPECIALIST_SUCCESS,
      FIND_SPECIALIST_FAILURE,
    ],
    promise: (api: IApi) => api.experts.findExpertAuto({ ...data }),
  };
};

export const FIND_SPECIALIST_MANUAL_REQUEST = `${prefix}/FIND_SPECIALIST_MANUAL_REQUEST`;
export const FIND_SPECIALIST_MANUAL_SUCCESS = `${prefix}/FIND_SPECIALIST_MANUAL_SUCCESS`;
export const FIND_SPECIALIST_MANUAL_FAILURE = `${prefix}/FIND_SPECIALIST_MANUAL_FAILURE`;

export const findSpecialistManual = (
  data: any,
) => {
  return {
    type: [FIND_SPECIALIST_MANUAL_REQUEST, FIND_SPECIALIST_MANUAL_SUCCESS, FIND_SPECIALIST_MANUAL_FAILURE],
    promise: (api: IApi) => api.experts.findExpertManual({ ...data })
  }
};

