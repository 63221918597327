import React, { FunctionComponent } from 'react';
import MultiCarousel, { DotProps } from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import classnames from './Carousel.module.scss';

import { SIZES } from '../../constants';

type CustomDotsProps = {
  hideDots?: boolean;
};

const CustomDots: FunctionComponent<DotProps & CustomDotsProps> =
  ({ hideDots, active, onClick, carouselState }) => {
    if (hideDots || carouselState?.totalItems === carouselState?.slidesToShow) return null;

    return (
      <button
        className={`${classnames.dot} ${active && classnames.activeDot}`}
        onClick={onClick}
      />
    );
  };

type Props = {
  children: React.ReactNode;
  containerClass: string;
  sliderClass: string;
  itemClass: string;
  hideDots?: boolean;
};

const RESPONSIVE = {
  desktop: {
    breakpoint: { max: SIZES.desktopMed, min: SIZES.desktop },
    items: 4,
  },
  tablet: {
    breakpoint: { max: SIZES.desktop, min: SIZES.mobile },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: SIZES.mobile, min: 0 },
    items: 1,
    partialVisibilityGutter: 80,
  },
};

const Carousel: FunctionComponent<Props> = ({
  children,
  containerClass,
  sliderClass,
  itemClass,
  hideDots,
}) => {

  return <MultiCarousel
    responsive={RESPONSIVE}
    draggable
    focusOnSelect={false}
    minimumTouchDrag={80}
    partialVisible
    slidesToSlide={1}
    swipeable
    transitionDuration={500}
    showDots={true}
    customDot={<CustomDots hideDots={hideDots} />}
    removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
    containerClass={containerClass}
    sliderClass={sliderClass}
    itemClass={itemClass}
  >
    {children}
  </MultiCarousel>;
};

Carousel.defaultProps = {
  hideDots: false,
};

export default Carousel;