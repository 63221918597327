import React, { Component } from 'react';
import {
  reduxForm,
  InjectedFormProps,
  Field,
} from 'redux-form';
import Form from '../../Form';
import InputField from '../../../Input';
import Spoiler from '../../../Spoiler';
// import validate from './validate';

import classnames from './Detailed.module.scss';

import { THEMES } from '../../../../constants/themes';

interface IProps {
  onBack: () => void;
  themeValues: string[];
  detailed: any;
};

class Detailed extends Component<InjectedFormProps<any, IProps> & IProps> {
  render() {
    const { handleSubmit, onBack, themeValues, detailed } = this.props;
    const options = themeValues
      .map((value) => THEMES && THEMES.filter((theme) => theme.id === value))
      .flat();

    let detailedValuesCount = 0;
    let themesCounter = 0;
    options.forEach((option) => themesCounter += option.detailed.length);

    const countObjElements = (obj:any) => {
      Object.entries(obj).forEach(
          ([key, value]: any) => {
            detailedValuesCount += Object.values(value).length;
          }
    )};

    detailed && countObjElements(detailed);

    return (
      <Form
        name='Підбір психолога'
        title='Виберіть відповідь, яка підходить вам найбільше'
        customValid={detailedValuesCount === themesCounter}
        useCustomValid
        onSubmit={handleSubmit}
        onBack={onBack}
        {...this.props}
      >
        {options.map((option: any) => {
          return (
            <Spoiler key={option.id} title={option.title}>
              {option.detailed.map((item: any) => {
                const fieldName = `detailed.${option.id}.${item.id}`;
                return (
                  <div key={option.id + item.id} className={classnames.option}>
                    <h4 className={classnames.title}>{item.title}</h4>
                    <div className={classnames.fields}>
                      <div className={classnames.field}>
                        <Field
                          name={fieldName}
                          type='radio'
                          component={InputField}
                          value='0'
                          labelStyles={classnames.label}
                          label='Про мене'
                        />
                      </div>
                      <div className={classnames.field}>
                        <Field
                          name={fieldName}
                          type='radio'
                          component={InputField}
                          labelStyles={classnames.label}
                          value='1'
                          label='Частково про мене'
                        />
                      </div>
                      <div className={classnames.field}>
                        <Field
                          name={fieldName}
                          type='radio'
                          component={InputField}
                          labelStyles={classnames.label}
                          value='2'
                          label='Не про мене'
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Spoiler>
          );
        })}
      </Form>
    );
  }
};

export default reduxForm<{}, IProps>({
  form: 'findSpecialist',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate
})(Detailed);