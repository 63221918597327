import { all } from 'redux-saga/effects';
import { History } from 'history';

import { IApi } from '../api';

import {
  findSpecialist,
  findSpecialistManual,
} from '../redux/findSpecialist/sagas';

export default function* rootSaga(history: History, api: IApi) {
  yield all([
    findSpecialist(history, api),
    findSpecialistManual(history, api)
  ]);
}
