import { FormErrors, getFormAsyncErrors } from "redux-form";
import _ from "lodash";

interface StepType {
  user: {
    name: string;
    email: string;
    phone: string;
    cookie: boolean;
  };
}

const validate = (values: StepType): FormErrors<StepType> => {
  const errors: FormErrors<StepType> = {};

  if (!_.has(values, "user.name") || _.isEmpty(values.user.name)) {
    _.set(errors, "user.name", "Будь ласка, заповніть поле");
  }

  if (!_.has(values, "user.email") || _.isEmpty(values.user.email)) {
    _.set(errors, "user.email", "Будь ласка, заповніть поле");
  }

  if (!_.has(values, "user.phone") || _.isEmpty(values.user.phone)) {
    _.set(errors, "user.phone", "Будь ласка, заповніть поле");
  }

  if (_.get(values, "user.phone") && (_.get(values, "user.phone").length < 10)) {
    _.set(errors, "user.phone", "Будь ласка, заповніть повний номер телефону");
  }

  if (!_.has(values, "user.cookie") || !_.get(values, "user.cookie")) {
    _.set(errors, "user.cookie", "Будь ласка, погодьтесь з пунктом");
  }

  if (!_.has(values, "user.connectType") || !_.get(values, "user.connectType")) {
    _.set(errors, "user.connectType", "Будь ласка, вкажіть спосіб зв'язку")
  }

  return errors;
};

export default validate;
