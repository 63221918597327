import React, { Component } from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import Form from '../../Form';
import InputField from '../../../Input';
import InfoBadge from '../../../InfoBadge';
// import validate from './validate';

import classnames from './Theme.module.scss';

import { THEMES } from '../../../../constants/themes';
import { ROUTES } from '../../../../constants/routes';

interface IProps {
  onBack(): void;
  themeValues: string[];
};

class Theme extends Component<InjectedFormProps<any, IProps> & IProps> {
  render() {
    const { handleSubmit, onBack, themeValues } = this.props;
    const themes = THEMES.map(theme => {
      return { label: theme.title, value: theme.id, labelStyles: classnames.label }
    });
    const customValid = themeValues && themeValues.length > 0;
    return (
      <Form
        name='Підбір психолога'
        title='Що вас зараз турбує?'
        subtitle='Оберіть, будь ласка, не більше 3-х пунктів'
        customValid={customValid}
        useCustomValid
        onSubmit={handleSubmit}
        onBack={onBack}
        {...this.props}
      >
        <Field
          {...this.props}
          type='checkbox'
          component={InputField}
          isMulti
          name='theme'
          options={themes}
          limit={3}
          inCard
          likeRadio
          size='big'
        />
        <div className={classnames.info}>
          <InfoBadge>
            Якщо вам зручніше описати, що вас турбує, в повідомленні —
            заповніть, будь ласка,
            <Link to={ROUTES.MANUALFINDSPECIALIST} className={classnames.link}>
              &nbsp; форму
            </Link>
            . Ми підберемо психолога відповідно до вашого запиту.
          </InfoBadge>
        </div>
      </Form>
    );
  }
};

export default reduxForm<{}, IProps>({
  form: 'findSpecialist',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate
})(Theme);
