import React, { Component } from 'react';

import classnames from './Sos.module.scss';

const info = [
  {
    phones: ["7733"],
    subtitle: "Національна цілодобова лінія запитань запобігання суїцидів та профілактики психічного здоров’я «Lifeline Ukraine»",
  },
  {
    phones: ["0 800 500 335", "116 123"],
    subtitle: "Національна гаряча лінія з попередження домашнього насильства, торгівлі людьми та гендерної дискримінації",
  },
  {
    phones: ["0 800 500 225", "116 111"],
    subtitle: "Національна дитяча гаряча лінія",
  },
];

type Props = {

};

class Sos extends Component<Props> {
  render() {
    return (
      <section className={classnames.sos} id='sos'>
        <h1 className={classnames.title}>Термінова допомога</h1>
        <p className={classnames.subtitle}>
          Якщо вам потрібна термінова психологічна допомога, зверніться в один з
          центрів екстреної підтримки
        </p>
        <div className={classnames.badges}>
          {info.map((element, i) => (
            <div className={classnames.badge} key={i}>
              <div className={classnames.label} />
              <div className={classnames.badgeText}>
                <h2 className={classnames.badgeTitle}>
                  {element.phones.map((phone, i) => (
                    <>
                      <a href={`tel:${phone}`}
                        className={classnames.phone}
                        key={i}
                      >
                        {phone}
                      </a>
                      {(i < element.phones.length-1) && ' / '}
                    </>
                  ))}
                </h2>
                <p className={classnames.badgeSubtitle}>{element.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default Sos;