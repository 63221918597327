import React, { memo, FunctionComponent, useContext, useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';

import classnames from './FlexibleImage.module.scss';

import WindowInnerWidthContext from '../../contexts/WindowInnerWidth';

type Props = {
  src: string;
  preferedCenter?: number;
  alt?: string;
  containerClassName?: string;
  className?: string;
}

const FlexibleImage: FunctionComponent<Props> = ({
  src = '',
  alt = '',
  preferedCenter = 0,
  containerClassName = '',
  className = '',
}) => {
  const [marginLeft, setMarginLeft] = useState(0);
  const [prevScreenWidth, setPrevScreenWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const screenWidth = useContext(WindowInnerWidthContext);

  const onLoadImage = ({
    clientWidth: imageClientWidth,
    naturalWidth: imageNaturalWidth,
  }: HTMLImageElement) => {
    if (!containerRef.current) {
      return;
    }

    const { clientWidth: containerClientWidth } = containerRef.current;
    let newMarginLeft: number = 0;

    const containerVerticalCenter = containerClientWidth / 2;

    if (preferedCenter === 0) {
      preferedCenter = imageClientWidth / 2;
    }

    if (imageClientWidth > 0 && imageNaturalWidth > 0 && imageClientWidth !== imageNaturalWidth) {
      preferedCenter = preferedCenter * (imageClientWidth / imageNaturalWidth);
    }

    newMarginLeft = preferedCenter - containerVerticalCenter;

    if (newMarginLeft < 0) {
      newMarginLeft = 0;
    }

    setMarginLeft(newMarginLeft);
  };

  useLayoutEffect(() => {
    if (imageRef.current && screenWidth !== prevScreenWidth) {
      onLoadImage(imageRef.current);
      setPrevScreenWidth(screenWidth);
    }
  });

  return (
    <div className={classNames(classnames.container, containerClassName)} ref={containerRef}>
      <img
        style={{
          marginLeft: `-${marginLeft}px`,
        }}
        className={classNames(classnames.image, className)}
        ref={imageRef}
        onLoad={event => onLoadImage((event.target as HTMLImageElement))}
        src={src}
        alt={alt}
      />
    </div>
  )
}

export default memo(FlexibleImage);
