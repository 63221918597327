import React, { useEffect } from 'react';
import {
  reduxForm,
  InjectedFormProps,
  Field, formValueSelector
} from 'redux-form';
import InputField from '../../../Input';
import Form from '../../Form';
import InfoBadge from '../../../InfoBadge';
import validate from './validate';
import { compose, normalizePhoneNew } from '../../../../utils';
import { CONNECT_TYPES } from '../../../../constants/connectType';

import classnames from './UserInfo.module.scss';

import secureImageSrc from '../../../../assets/images/secure.svg';
import { connect } from "react-redux";

const customUserInput: React.FC<{
  meta: {
    error: string;
    dirty: boolean;
  };
  type: string;
  name: string;
  input: any;
}> = ({
  input,
  type,
  name,
  meta: {
    dirty,
    error,
  }
}) => {
  return (
    <>
      <input name={name} type={type} {...input}/>
      {dirty && error && (
        <p className={classnames.errorMessage}>{error}</p>
      )}
    </>
  );
}

interface IProps {
  onSubmit(props?:any): void;
  onBack: () => void;
  userSex?: string;
  isTelegramSelected: boolean;
}

const UserInfo: React.FC<InjectedFormProps<any, IProps> & IProps> = (props) => {

  const { onSubmit, onBack, valid, autofill, userSex, isTelegramSelected } = props;

    useEffect(() => {
      const userFields = JSON.parse(window.localStorage.getItem("userInfo")!) || {};

      Object.entries(userFields).forEach(([key, value]) => {
        autofill(`user.${key}`, value)
      })
    }, [autofill])

    return (
      <Form
        name='Підбір психолога'
        title='Розкажіть про себе'
        subtitle='Будь ласка, заповніть поля'
        valid={valid}
        onSubmit={onSubmit}
        onBack={onBack}
        end
        {...props}
      >
        <div className={classnames.container}>
          <p className={classnames.message}>
            * - вказані поля обов'язкові для заповнення
          </p>
          <div className={classnames.boxOne}>
            <div className={classnames.fieldBox}>
              <Field
                name='user.name'
                component={InputField}
                type='text'
                value=''
                label='Ваше ім’я *'
                placeholder='Введіть ім’я'
                inputStyles={classnames.name}
              />
            </div>
            <div className={`${classnames.fieldBox} ${classnames.ageBox}`}>
              <Field
                name='user.age'
                component={InputField}
                type='number'
                value=''
                label='Ваш вік'
                inputStyles={classnames.age}
              />
            </div>
            <div className={`${classnames.userSexBox} ${classnames.fieldBox}`}>
              Ваша стать
              <div className={classnames.fields}>
                <label className={classnames.checkboxField}>
                  <Field
                    name='user.sex'
                    component='input'
                    type='radio'
                    value='man'
                  />
                  <span>Ч</span>
                </label>
                {!userSex && <div className={classnames.line}></div>}
                <label className={classnames.checkboxField}>
                  <Field
                    name='user.sex'
                    component='input'
                    type='radio'
                    value='woman'
                  />
                  <span>Ж</span>
                </label>
              </div>
            </div>
          </div>
          <div className={classnames.fieldBox}>
            <Field
              name='user.email'
              component={InputField}
              type='email'
              value=''
              label='Ваш email *'
              placeholder='name@email.com'
              inputStyles={classnames.email}
            />
          </div>
          <div
            className={`${classnames.checkboxContainer} ${classnames.fieldBox}`}
          >
            <label className={classnames.box}>
              Хочу отримувати статті і новини з психотерапії
              <Field
                name='user.subscribe'
                component='input'
                type='checkbox'
                value=''
              />
              <span className={classnames.mark}></span>
            </label>
          </div>

          <div className={classnames.boxOne}>
            <div className={classnames.fieldBox}>
              <Field
                name='user.connectType'
                component={InputField}
                type='select'
                label='Пріоритетний спосіб зв’язку *'
                inputStyles={classnames.connection}
                selectOptions={CONNECT_TYPES}
              />
            </div>
            {
                isTelegramSelected &&
                <div className={classnames.fieldBox}>
                  <Field
                      name='user.telegramName'
                      component={InputField}
                      type='input-select'
                      label='Telegram псевдонім (username) *'
                      placeholder='@ваш_псевдонім'
                      labelStyles={classnames.element}
                      options={[{label: 'Не задано', value: 'Не задано'}, {label: 'Свій варіант', value: ''}]}
                  />
                </div>
            }
            <div className={classnames.fieldBox}>
              <Field
                name='user.phone'
                component={InputField}
                value=''
                label='Ваш телефон *'
                placeholder='___ ___ ___ __ __'
                inputStyles={classnames.phone}
                type='tel'
                normalize={normalizePhoneNew}
              />
            </div>
          </div>
          <div
            className={`${classnames.checkboxContainer} ${classnames.fieldBox}`}
          >
            <label className={classnames.box}>
              Я погоджуюсь на використання файлів cookie та з{" "}
              <a
                href='/EgoBalance_Privacy_Policy.pdf'
                target='_blank'
                className={classnames.privacy}
              >
                Політикою конфіденційності
              </a>
              *
              <Field
                name='user.cookie'
                component={customUserInput}
                type='checkbox'
                value=''
                label=''
              />
              <span className={classnames.mark}></span>
            </label>
          </div>
        </div>
        <div className={classnames.fieldBox}>
          <InfoBadge customImageSrc={secureImageSrc}>
            Ми не передаємо ваші особисті дані третім особам. Вони залишаються
            на платформі та використовуються виключно для підбору психолога,
            зв'язку з вами та інформування про наші новини (за вашої згоди).
          </InfoBadge>
        </div>
      </Form>
    );
};


const selector = formValueSelector('findSpecialist');

const formConnect = reduxForm<{}, IProps>({
  form: 'findSpecialist',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
});

const reduxConnect = connect((state) => {
  const user = selector(state, 'user');
  let isTelegramSelected = false;

  if (user?.connectType) {
    isTelegramSelected = user.connectType === 'telegram'
  }
  return {isTelegramSelected};
})


export default compose(formConnect, reduxConnect)(UserInfo);
