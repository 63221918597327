import React from 'react';

import classnames from './Card.module.scss';

const Card: React.FC<{ checked?: boolean }> = ({ children, checked }) => {
  return (
    <div className={`${classnames.card} ${checked && classnames.isChecked}`}>
      {children}
    </div>
  )
};

export default Card;