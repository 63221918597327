import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faCheck, faPlayCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash/debounce';

import api from './api';
import configureStore, { history } from './redux/store';

import Routes from './routes';
import Meta from './components/Meta';
import WindowInnerWidthContext from './contexts/WindowInnerWidth';

type State = {
  innerWidth: number;
};

const store = configureStore(api, history);
library.add(fab, faCheckCircle, faCheck, faPlayCircle, faSpinner);
history.listen((_) => {
  if (!window.location.hash) {
    window.scrollTo(0, 0);
  }
});

const ONE_SECOND_IN_MILLISECONDS = 1000;

export default class App extends React.Component {
  state: State = {
    innerWidth: window.innerWidth,
  };

  debounced: any;

  componentDidMount() {
    this.debounced = debounce(() => {
      this.setState({ innerWidth: window.innerWidth });
    }, ONE_SECOND_IN_MILLISECONDS);
    window.addEventListener('resize', this.debounced);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounced);
    this.debounced.cancel();
  }

  render() {
    return (
      <Provider store={store}>
        <WindowInnerWidthContext.Provider value={this.state.innerWidth}>
          <ConnectedRouter history={history}>
            <Meta page="default" />
            <LastLocationProvider>
              <Routes />
            </LastLocationProvider>
            {/*<OnExitPopup />*/}
          </ConnectedRouter>
        </WindowInnerWidthContext.Provider>
      </Provider>
    );
  }
}
