import React, { useEffect } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import InputField from '../../Input';
import CustomCheckbox from '../../Input/CustomCheckbox';
import FormExpert from '../FormExpert';
import FormSuccess from '../FormSuccess';
import { CONNECT_TYPES } from '../../../constants/connectType';
import { normalizePhoneNew} from '../../../utils';
import Loader from 'react-loader-spinner';

import classnames from './ContactExpertForm.module.scss';

export interface IProps {
  expertName: string;
  expertPhoto: string;
  isTelegramSelected: boolean;
  onModalClose(): void;
  loadingForm: boolean;
  isAfterTest?: boolean;
}

const ContactExpertForm: React.FC<InjectedFormProps<any, IProps> & IProps> = (props) => {
  const {
    handleSubmit,
    valid,
    expertName,
    expertPhoto,
    submitSucceeded,
    isTelegramSelected,
    onModalClose,
    loadingForm,
    autofill,
    isAfterTest,
  } = props;

  useEffect(() => {
    const userFields = JSON.parse(window.localStorage.getItem("userInfo")!) || {};

    Object.entries(userFields).forEach(([key, value]) => {
      if (key === "sex" || key === "subscribe") return null;
      autofill(key, value);
    });
  }, [autofill]);

  useEffect(() => {
    const leaveSiteAlert = function (e:any) {
      e.preventDefault();
      return e.returnValue = '';
    }

    window.addEventListener('beforeunload', leaveSiteAlert);

    return () =>  window.removeEventListener('beforeunload', leaveSiteAlert)
  });


  const loader = (
    <div className={classnames.loader}>
      <Loader
        type='Circles'
        color='#FF8448'
        height={60}
        width={60}
      />
      <p className={classnames.text}>Зачекайте будь-ласка, йде обробка запиту</p>
    </div>
  );

  return loadingForm ? (
    loader
  ) : submitSucceeded ? (
    <FormSuccess onClose={onModalClose} />
  ) : (
    <FormExpert
      title={`Передаємо контакти психологу ${expertName}`}
      photo={expertPhoto}
      submitButtonText='Домовитися про консультацію'
      isValid={valid}
      onSubmit={handleSubmit}
      isAfterTest={isAfterTest}
      {...props}
    >
      <Field
        name='name'
        component={InputField}
        type='text'
        label='Ваше ім’я *'
        placeholder='Ваше ім’я'
        labelStyles={classnames.element}
      />
      <Field
        name='email'
        component={InputField}
        type='email'
        value=''
        label='Ваш email *'
        placeholder='name@email.com'
        labelStyles={classnames.element}
      />
      <Field
        name='connectType'
        component={InputField}
        type='select'
        label='Пріоритетний спосіб зв’язку *'
        labelStyles={classnames.element}
        selectOptions={CONNECT_TYPES}
      />
      <Field
        name='phone'
        component={InputField}
        value=''
        label='Ваш телефон *'
        placeholder='___ ___ ___ __ __'
        labelStyles={classnames.element}
        type='tel'
        normalize={normalizePhoneNew}
      />
        {
            isTelegramSelected && <Field
                name='telegramName'
                component={InputField}
                type='input-select'
                label='Telegram псевдонім (username) *'
                placeholder='@ваш_псевдонім'
                labelStyles={classnames.element}
                options={[{label: 'Не задано', value: 'Не задано'}, {label: 'Свій варіант', value: ''}]}
            />
        }
      <Field
        name='comment'
        component={InputField}
        type='textarea'
        value=''
        rows={5}
        label='Ваш коментар'
        placeholder='Будь ласка, тут можете додати ваш коментар для спеціаліста'
        labelStyles={classnames.elementComment}
      />
      <div className={classnames.checkboxContainer}>
        <Field
          name='cookie'
          component={CustomCheckbox}
          type='checkbox'
          value=''
          label='Я погоджуюсь на використання файлів cookie та з <a href="/EgoBalance_Privacy_Policy.pdf" target="_blank">Політикою
        конфіденційності</a>*'
        />
      </div>
    </FormExpert>
  );
}

export default ContactExpertForm;

