import React, { Component } from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import InputField from '../../../Input';
import Form from '../../Form';
import InfoBadge from '../../../InfoBadge';
import validate from './validate';

import classnames from './ThemeManual.module.scss';

interface IProps {
  onBack: () => void;
};

class ThemeManual extends Component<InjectedFormProps<any, IProps> & IProps> {
  render() {
    const { handleSubmit, onBack, valid } = this.props;

    return (
      <Form
        name="Підбір психолога"
        title="З чим потрібна допомога?"
        subtitle="Опишіть вашу проблему, щоби ми підібрали фахівця з досвідом вирішення схожих завдань"
        valid={valid}
        onSubmit={handleSubmit}
        onBack={onBack}
        {...this.props}
      >
        <Field
          name="theme.manual.request"
          component={InputField}
          type="textarea"
          rows={10}
          value=""
          label=""
          placeholder="З чим хочете розібратися? Є ситуації, в яких ви б хотіли змінити свою поведінку? Є симптоми? Розкажіть, як вони з'явилися."
        />
        <div className={classnames.info}>
          <InfoBadge>
            Чим докладніше опишете потребу, тим точніше ми підберемо вам психотерапевта.
          </InfoBadge>
        </div>
      </Form>
    )
  }
};

export default reduxForm<{}, IProps>({
  form: 'findSpecialist',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(ThemeManual);