import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IRootState } from '../../../redux/reducer';
import { getList } from '../../../redux/expert/actions';
import { loading, error, experts } from '../../../redux/expert/selectors';
import { isAnyModalOpen } from '../../../redux/util/selectors';
import Tabs from './Tabs';

type StateProps = {
  loading: boolean;
  error: any;
  experts: any;
  isAnyModalOpen: boolean;
};

const selector = createStructuredSelector<IRootState, StateProps>({
  loading,
  error,
  experts,
  isAnyModalOpen,
});

const actions = {
  getList,
};

export { Tabs };

export default connect(selector, actions)(Tabs);
