import React from 'react';
import { Button } from '../../Button';

import classnames from './FormSuccess.module.scss';

import checkIcon from '../../../assets/images/check_1.svg';

interface IProps {
  onClose(): void
}

const FormSuccess: React.FC<IProps> = ({onClose}) => {
  return (
    <div className={classnames.container}>
      <div className={classnames.imageContainer}>
        <img src={checkIcon} alt='check' />
      </div>
      <h2 className={classnames.title}>Успіх</h2>
      <p className={classnames.subtitle}>Дякуємо, що заповнили усі поля.</p>
      <p className={classnames.subtitle}>Ми сконтактуємось з вами якнайшвидше!</p>
      <Button onClick={onClose} buttonStyles={classnames.button}>
        <span className={classnames.buttonText}>Зрозуміло</span>
      </Button>
    </div>
  );
};

export default FormSuccess;
