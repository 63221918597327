import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import { IRootState } from '../../../redux/reducer';
import {
  connectExpert,
} from '../../../redux/expert/actions';
import {
  expert,
  experts,
  loadingForm,
} from '../../../redux/expert/selectors';
import ChooseExpert from './ChooseExpert';

type OwnProps = {
  expertId: string;
};

type StateProps = {
  loadingForm: boolean;
  expert: any;
  experts: {
    expertId: string;
  }[];
};

type DispatchProps = {
  connectExpert: (data: {
    name: string;
    email: string;
    phone: string;
    connectType?: string | undefined;
    cookie: boolean;
    comment?: string | undefined;
    expertName: string;
    expertId: string;
  }) => void;
};

const selector = createStructuredSelector<IRootState, StateProps>({
  loadingForm,
  expert,
  experts,
});

const actions = {
  connectExpert,
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
  const { expert, experts } = stateProps;
  const { expertId: selectedExpertId } = ownProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    expert: (expert.expertId === selectedExpertId && expert) ||
      experts.find(({ expertId }) => expertId === selectedExpertId),
  };
};

export default connect(selector, actions, mergeProps)(withRouter(ChooseExpert));
