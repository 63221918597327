import React, { useState } from 'react';
import {
  reduxForm,
  InjectedFormProps,
} from 'redux-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Form from '../../Form';

import classnames from './Priority.module.scss';
import colors from '../../../../assets/styles/colorsToJs.module.scss';

import { THEMES } from '../../../../constants/themes';

import dndAnimation from '../../../../assets/images/dndAnimation.gif';

interface IProps {
  onBack: () => void;
  themeValues: string[];
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  minHeight: "60px",
  border: `1px solid ${colors['blue-middle']}`,
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 20px 10px 30px",
  marginTop: "10px",
  boxSizing: "border-box",
  backgroundColor: colors['white'],
  boxShadow: '0px 4px 20px rgba(181, 181, 181, 0.2)',

  opacity: isDragging ? 0 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver:any) => ({
  width: '100%',
});

const Priority: React.FC<InjectedFormProps<any, IProps> & IProps> = (props) => {
    const { handleSubmit, onBack, themeValues, change } = props;
    
    const options = themeValues.map(value => THEMES && THEMES.filter(theme => theme.id === value)).flat();

    const [elements, setElements] = useState(options || []);

    const reorder = (list:any, startIndex:any, endIndex:any) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const onDragEnd = (result:any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items:any = reorder(
        elements,
        result.source.index,
        result.destination.index
      );

      setElements(items);
      const retThemes = items.map((item:any) => item.id);
      change('theme', retThemes);
    }
    return (
      <Form
        name='Підбір психолога'
        title='Визначте пріоритетність'
        subtitle='На перше місце поставте те, що вас турбує найбільше. Щоб поміняти місцями, натисніть на вибраний рядок та перетягніть його вгору або вниз.'
        valid={true}
        onSubmit={handleSubmit}
        onBack={onBack}
        {...props}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(droppableProvided, droppableSnapshot) => (
              <div
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                {elements &&
                  elements.map((option: any, index: number) => (
                    <Draggable
                      key={option.id}
                      draggableId={option.id}
                      index={index}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={getItemStyle(
                            draggableSnapshot.isDragging,
                            draggableProvided.draggableProps.style
                          )}
                        >
                          <h4 className={classnames.text}>{option.title}</h4>
                          <div className={classnames.iconContainer}>
                            <div className={classnames.icon}></div>
                            <div className={classnames.icon}></div>
                            <div className={classnames.icon}></div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={classnames.gifContainer}>
          <img src={dndAnimation} alt="animation"/>
        </div>
      </Form>
    );
};

export default reduxForm<{}, IProps>({
  form: 'findSpecialist',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Priority);