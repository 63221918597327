import superagent from 'superagent';
import config from '../config';
export default class ApiClient {
  prefix: string;
  token: string | null;

  constructor(prefix: string) {
    if (!prefix) {
      throw new Error('[apiPrefix] required');
    }
    this.prefix = prefix;
    this.token = window.localStorage.getItem('token') || null;
  }

  setToken(token: string) {
    this.token = token;
    window.localStorage.setItem('token', token);
  }

  removeToken() {
    this.token = null;
    window.localStorage.removeItem('token');
  }

  get(url: string, params: object = {}) {
    return this._request({ url, method: 'get', params });
  }

  post(url: string, body: object = {}) {
    return this._request({ url, method: 'post', body });
  }

  patch(url: string, body: object = {}) {
    return this._request({ url, method: 'patch', body });
  }

  put(url: string, body: object = {}) {
    return this._request({ url, method: 'put', body });
  }

  delete(url: string, body: object = {}) {
    return this._request({ url, method: 'delete', body });
  }

  _request({
    url,
    method,
    params,
    body,
  }: {
    url: string;
    method: string;
    params?: object;
    body?: object;
  }) {

    const { api_host, api_port } = config;
    
    // @ts-ignore
    const req = superagent[method](`${api_host}${api_port && `:${api_port}`}${this.prefix}${url}`);

    if (params) {
      req.query(params);
    }
    if (body) {
      req.send(body);
    }
    if (this.token) {
      req.set('Authorization', `Bearer ${this.token}`);
    }

    return req.then((res: any) => {
      if (res.status !== 200) {
        throw res.body.error;
      }
      return res.body.data;
    });
  }
}
