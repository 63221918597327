import React, {useState, useEffect, useRef, useCallback} from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {MENU_LABELS} from '../../constants/menu';
import {ROUTES} from '../../constants/routes';
import {Button} from '../Button';

import {useLocation} from "react-router-dom";

import {scrollWidthOffset} from '../../utils';

import classnames from './Menu.module.scss';

import logoSrc from '../../assets/images/logo_main.svg';
import awardSrc from '../../assets/images/award.png';
import {QUERY} from "../../constants/query";

const LinksWrapper: React.FC<{ onClose: any }> = ({onClose, children}) => {
    const ref = useRef(null);
    const escapeListener = useCallback((e: KeyboardEvent) => {
        if (e.keyCode === 27) {
            onClose();
        }
    }, []);
    const clickListener = useCallback(
        (e: MouseEvent) => {
            if (!(ref.current! as any).contains(e.target)) {
                onClose?.();
            }
        },
        [ref.current]
    );
    useEffect(() => {
        document.addEventListener("click", clickListener);
        document.addEventListener("keyup", escapeListener);
        return () => {
            document.removeEventListener("click", clickListener);
            document.removeEventListener("keyup", escapeListener);
        };
    }, []);
    return <div ref={ref}>{children}</div>;
};

const Links: React.FC<{ isMenuOpen: boolean; onClose: any }> = ({
                                                                    isMenuOpen,
                                                                    onClose
                                                                }) => {
    return (
        <div
            className={`${classnames.links} ${isMenuOpen && classnames.linksIsOpen}`}
        >
            {MENU_LABELS.map((label: { title: string; route: string | object }) => (
                <div
                    className={classnames.linkItem}
                    onClick={onClose}
                    key={label.title}
                >
                    <Link
                        smooth
                        to={label.route}
                        key={label.title}
                        className={classnames.link}
                        scroll={(el) => scrollWidthOffset(el)}
                    >
                        {label.title}
                    </Link>
                    <div className={classnames.linkLine}></div>
                </div>
            ))}
        </div>
    );
};

interface IProps {
    isHomepage?: boolean;
}

const Menu = React.forwardRef((props: IProps, ref: React.Ref<HTMLDivElement>) => {
    const {isHomepage} = props;
    const [isMenuOpen, setHandleMenu] = useState(false);
    const [isOnTop, setHandleScroll] = useState(true);
    const [isShown, setHandleShow] = useState(false);
    const [isAwardHovered, setIsAwardHovered] = useState(false);
    const awardRef = useRef<number>()

    const {search} = useLocation();

    const queryReg = new RegExp(QUERY.AFTER_TEST, 'ig');
    const isAfterTest = queryReg.test(search);

    const handleMenuClick = () => {
        setHandleMenu(!isMenuOpen);
    };


    function handleHover(isHovered: boolean) {
        if (!isHovered) {
          // @ts-ignore
            awardRef.current = setTimeout(() => setIsAwardHovered(isHovered), 200)
            return
        }

        clearTimeout(awardRef.current);

        setIsAwardHovered(isHovered)
    }


    useEffect(() => {
        function handleScroll() {
            const scrollTop = window.pageYOffset;
            setHandleScroll(scrollTop > 0);
            setHandleShow(scrollTop > (isHomepage ? (ref as any).current.offsetTop : 0));
        }

        handleScroll();
        document.addEventListener("scroll", handleScroll, true);

        return () => {
            document.removeEventListener("scroll", handleScroll, true);
        };
    }, []);

    return (
        <section
            className={`${classnames.container} ${
                !isOnTop && isHomepage && classnames.isOnTop
            }`}
        >
            <div className={classnames.logoContainer}>
                <Link to={ROUTES.HOME}>
                    <div className={classnames.logo}>
                        <img src={logoSrc} alt='logo'/>
                    </div>
                </Link>
            </div>
            <div
                className={`${classnames.menu} ${
                    isMenuOpen && classnames.menuIsOpened
                }`}
            >
                {isMenuOpen ? (
                    <LinksWrapper onClose={() => setHandleMenu(false)}>
                        <Links
                            isMenuOpen={isMenuOpen}
                            onClose={() => setHandleMenu(false)}
                        />
                    </LinksWrapper>
                ) : (
                    <Links isMenuOpen={isMenuOpen} onClose={() => setHandleMenu(false)}/>
                )}

                {isAfterTest ? null : (<Link to={ROUTES.FINDSPECIALIST}>
                    <Button
                        buttonStyles={`${classnames.button} ${
                            isMenuOpen && classnames.buttonIsOpen
                        } ${isShown && classnames.isShown}`}
                    >
                        <span className={classnames.buttonText}>Знайти психолога</span>
                    </Button>
                </Link>)}


                <span className={`${classnames.awardLink} ${isAfterTest ? classnames.left : ''}`}
                      onMouseEnter={() => handleHover(true)}
                      onMouseLeave={() => handleHover(false)}>
                    <a href="/award_description.pdf" target='_blank'>
                    <img className={classnames.award} src={awardSrc} title='Платформа психологічної допомоги 2023 року'
                         alt="award"/>

                </a>
                    <span className={`${classnames.tooltip} ${isAwardHovered ? classnames.hovered : ''}`}>
                        <a target='_blank' rel='noopener noreferrer' href="https://award.com.ua/winners/ego-balance/">Перейти на сайт “Вибір країни“</a>
                    </span>
                </span>


                <div
                    className={`${classnames.lang} ${
                        isMenuOpen && classnames.langIsOpen
                    }`}
                >
                    <a href='#' className={classnames.langLink}>
                        Укр
                    </a>
                    <a href='#' className={classnames.langLink}>
                        Рус
                    </a>
                </div>
                <div className={classnames.bars} onClick={() => handleMenuClick()}>
                    {!isMenuOpen ? (
                        <div className={classnames.isClosed}>
                            <div className={classnames.bar}/>
                            <div className={classnames.bar}/>
                            <div className={classnames.bar}/>
                        </div>
                    ) : (
                        <div className={classnames.isOpened}>
                            <div className={classnames.crossBar}/>
                            <div className={classnames.crossBar}/>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
});

export default Menu;
