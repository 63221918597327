import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import classnames from './Badge.module.scss';

import { ReactComponent as PlayCircleIcon } from '../../assets/images/play_circle.svg';
import { Button } from '../Button';
import FlexibleImage from '../FlexibleImage';
import ShowExpertVideoPopup from '../Popups/ShowExpertVideo';

import { ROUTES } from '../../constants/routes';
import { QUERY } from '../../constants/query';
import { EXPERT_PHOTO_FACE_VERTICAL_CENER } from '../../constants/expertPhoto';

const Badge: React.FC<{
  imageUrl: string;
  title: string;
  subtitle: string | React.ReactNode;
  description?: string | null;
  isLine?: boolean | null;
  linkTitle: string;
  expertId: string;
  videoUrl: string;
  className?: string;
  haveTestResult?: boolean;
}> = ({
  imageUrl,
  title,
  subtitle,
  description,
  isLine,
  linkTitle,
  expertId,
  videoUrl,
  className,
  haveTestResult,
}) => {
  return (
    <div className={classNames(classnames.badgeContainer, className)}>
      <div className={classnames.imageContainer}>
      <ShowExpertVideoPopup expertId={expertId} videoUrl={videoUrl}>
        <Button
          buttonStyles={classnames.badgeShowVideoButton}
          disabled={!videoUrl}
        >
          <FlexibleImage
            containerClassName={classnames.image}
            preferedCenter={EXPERT_PHOTO_FACE_VERTICAL_CENER}
            src={imageUrl}
            alt="Expert"
          />
          {videoUrl && <div className={classnames.badgeButtonChooseText}>
            <PlayCircleIcon />
          </div>}
        </Button>
      </ShowExpertVideoPopup>
      </div>
      <Link
        className={classnames.info}
        to={`${ROUTES.EXPERTS}/${expertId}${haveTestResult ? `?${QUERY.AFTER_TEST}` : ''}`}
      >
        <h4 className={classnames.title}>{title}</h4>
        <p className={classnames.subtitle}>{subtitle}</p>
        {isLine && <hr className={classnames.line} />}
        {description && <p className={classnames.description}>{description}</p>}
        <p className={classnames.link}>
          {linkTitle}
        </p>
      </Link>
    </div>
  );
};

Badge.defaultProps = {
  className: '',
  haveTestResult: false,
};

export default Badge;