import React from 'react';
import ProgressBar from '../../ProgressBar';

const FormContainer: React.FC<{color: string, points: Array<string>}> = ({color, points, children}) => {
  return (
    <div>
      <ProgressBar color={color} points={points} />
        {children}
    </div>
  )
};

export default FormContainer;