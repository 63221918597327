import React, { useLayoutEffect, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import Cta from '../../components/HomePage/Cta';
import Footer from '../../components/Footer';
import Badge from '../../components/Badge';
import { typePrice, renderPrice } from '../../utils';
import { ROUTES } from '../../constants/routes';
import LoaderCircle from '../../components/LoaderCircle';
import Meta from '../../components/Meta';

import classnames from './Experts.module.scss';
import config from "../../config";

const textInfo = [
  "Мають вищу психологічну або медичну освіту",
  "Мають сертифікат психотерапевта за певним напрямком",
  "Перевірені нашим сервісом та пройшли інтерв’ю",
];

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

interface IProps {
  getList(limit?:number): void;
  experts: Array<object>;
  loading: boolean;
  isResult: boolean;
}

const Experts: React.FC<IProps> = (props) => {
  const { experts, getList, loading, isResult } = props;
  const width = useWindowSize();
  // const [count, setCount] = useState(9);

  useEffect(() => {
    !isResult && getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Meta page="experts" />
      <Menu />
      <section className={classnames.specContainer}>
        {loading ? (
          <LoaderCircle />
        ) : (
          <>
            {
              isResult ? (
                <div>
                  <h1 className={classnames.title}>
                    Оберіть психолога з рекомендованих
                  </h1>
                  <p className={classnames.subTitle}>Спеціалісти за вашим запитом</p>
                </div>
              ) : (
                <h1 className={classnames.title}>
                  Наші спеціалісти
                </h1>
              )
            }
            {isResult && (experts.length > 2 || width < 1024) &&
              <Link to={ROUTES.EXPERTS} className={classnames.linkToAll}>
                  Усі спеціалісти &#8594;
              </Link>
            }
            <div className={classnames.badgeContainer}>
              {experts &&
                experts.map((expert: any, i) => {
                  const {
                    profile: { firstName, photoUrl, videoUrl },
                    therapy: { price },
                    expertId,
                  } = expert;
                  // if (i + 1 > count) return null;
                  const number =
                    experts.length > 2
                      ? width >= 1024
                        ? 2
                        : isResult
                          ? 2
                          : 3
                      : experts.length - 1;
                  const infoPanel = i === number && (
                    <div className={classnames.specInfo} key='specInfo'>
                      <h3 className={classnames.titleInfo}>
                        Наші спеціалісти:
                      </h3>
                      <div className={classnames.textInfo}>
                        {textInfo.map((item) => (
                          <div className={classnames.textBox} key={item + i}>
                            <FontAwesomeIcon
                              icon='check-circle'
                              className={classnames.icon}
                            />
                            <p className={classnames.text}>{item}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                  return (
                    <>
                      <Badge
                        key={firstName + i}
                        className={classnames.badge}
                        expertId={expertId}
                        title={firstName}
                        imageUrl={`${config.static_host}/${expertId}/${photoUrl}`}
                        videoUrl={videoUrl}
                        haveTestResult={isResult}
                        subtitle={
                          <span>
                            <b>
                              {renderPrice({
                                online: price.online,
                                offline: price.offline,
                              })}
                            </b>{" "}
                            за сеанс
                          </span>
                        }
                        isLine
                        description={typePrice({
                          online: price.online,
                          offline: price.offline,
                        })}
                        linkTitle='Детальніше &#8594;'
                      />
                      {experts.length <= 2
                        && experts.length !== 0
                        && isResult
                        && i === experts.length-1
                        && width >= 1024
                        && (
                        <div className= { classnames.badge } key={i + 1}>
                          <Link to={ROUTES.EXPERTS} className={classnames.linkToAllLess}>
                            Усі спеціалісти &#8594;
                          </Link>
                        </div>
                      )}
                      {infoPanel}
                    </>
                  );
                })}
            </div>
            {/*{count < experts.length && (*/}
            {/*  <Button*/}
            {/*    buttonStyles={classnames.button}*/}
            {/*    onClick={() => setCount(count + 9)}*/}
            {/*  >*/}
            {/*    <span className={classnames.buttonText}>Показати ще</span>*/}
            {/*  </Button>*/}
            {/*)}*/}
          </>
        )}
      </section>
      <Cta />
      <Footer />
    </>
  );
};

export default Experts;
