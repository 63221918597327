import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import FormContainer from '../../components/Form/Container';
import Type from '../../components/Form/FindExpert/Type';
import Theme from '../../components/Form/FindExpert/Theme';
import Priority from '../../components/Form/FindExpert/Proirity';
import Detailed from '../../components/Form/FindExpert/Detailed';
import UserInfo from '../../components/Form/FindExpert/UserInfo';
import { IProps } from './interface';

const typeRoute = `${ROUTES.AUTOFINDSPECIALIST}/type`,
  themeRoute = `${ROUTES.AUTOFINDSPECIALIST}/theme`,
  priorityRoute = `${ROUTES.AUTOFINDSPECIALIST}/priority`,
  detailedRoute = `${ROUTES.AUTOFINDSPECIALIST}/detailed`,
  userInfoRoute = `${ROUTES.AUTOFINDSPECIALIST}/user-info`;

const points = [
  'Формат',
  'Причини звернення',
  'Про себе'
];

const FormAutoFindSpecialist: React.FC = (props:any) => {
  const [page, setPage] = useState(1);

  const { onSubmit, themeValues, detailed, userSex } = props;

  const isPriority = themeValues && themeValues.length > 1;
  
  const submit = (values:any) => {
    const { user, theme, type, detailed } = values;

    const data = {
      ...user,
      userRequest: {
        type,
        theme,
        detailed
      }
    };

    window.localStorage.removeItem("userInfo");
    window.localStorage.setItem("userInfo", JSON.stringify(user));

    onSubmit(data);
  };

  return (
    <>
      <FormContainer color='orange' points={points}>
        <Redirect
          from={ROUTES.AUTOFINDSPECIALIST}
          exact
          to={`${ROUTES.AUTOFINDSPECIALIST}/type`}
        />
        {page === 1 && (
          <Route
            path={typeRoute}
            render={(props) => (
              <Type
                {...props}
                onSubmit={() => {
                  props.history.push(themeRoute);
                  setPage(page + 1);
                }}
              />
            )}
          />
        )}
        {page === 2 && (
          <Route
            path={themeRoute}
            render={(props) => (
              <Theme
                {...props}
                themeValues={themeValues}
                onSubmit={() => {
                  props.history.push(
                    isPriority ? priorityRoute : detailedRoute
                  );
                  setPage(page + (isPriority ? 1 : 2));
                }}
                onBack={() => {
                  props.history.push(typeRoute);
                  setPage(page - 1);
                }}
              />
            )}
          />
        )}
        {page === 3 && isPriority && (
          <Route
            path={priorityRoute}
            render={(props) => (
              <Priority
                themeValues={themeValues}
                {...props}
                onSubmit={() => {
                  props.history.push(detailedRoute);
                  setPage(page + 1);
                }}
                onBack={() => {
                  props.history.push(themeRoute);
                  setPage(page - 1);
                }}
              />
            )}
          />
        )}
        {page === 4 && (
          <Route
            path={detailedRoute}
            render={(props) => (
              <Detailed
                themeValues={themeValues}
                detailed={detailed}
                {...props}
                onSubmit={() => {
                  props.history.push(userInfoRoute);
                  setPage(page + 1);
                }}
                onBack={() => {
                  props.history.push(isPriority ? priorityRoute : themeRoute);
                  setPage(page - (isPriority ? 1 : 2));
                }}
              />
            )}
          />
        )}
        {page === 5 && (
          <Route
            path={userInfoRoute}
            render={(props) => (
              <UserInfo
                {...props}
                onSubmit={submit}
                userSex={userSex}
                onBack={() => {
                  props.history.push(detailedRoute);
                  setPage(page - 1);
                }}
              />
            )}
          />
        )}
      </FormContainer>
    </>
  );
}

export default FormAutoFindSpecialist;