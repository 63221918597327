import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import HomePage from './pages/HomePage';
import Experts from './pages/Experts';
import Expert from './pages/Expert';
import ExpertResult from './pages/ExpertResults';
import FormFindSpecialist from './pages/FormFindSpecialist';
import FindSpecialist from './pages/FindSpecialist';
import FindManualFindSpecialist from './pages/FormManualFindSpecialist';
import FormSuccess from './pages/FormSuccess';
import NotFount from './pages/NotFount';
import Reminder from "./pages/Reminder";
import {ROUTES} from './constants/routes';

const Routes = () => (
    <Switch>
        <Route path={ROUTES.HOME} component={HomePage} exact/>
        <Route path={ROUTES.EXPERTS} component={Experts} exact/>
        <Route path={ROUTES.FINDEXPERTRESULTS} component={ExpertResult} excat/>
        <Route path={`${ROUTES.EXPERTS}/:id`} component={Expert}/>
        <Route path={ROUTES.FINDSPECIALIST} component={FindSpecialist} exact/>
        <Route path={ROUTES.AUTOFINDSPECIALIST} component={FormFindSpecialist}/>
        <Route path={ROUTES.REMINDER} component={Reminder} exact/>
        <Route
            path={ROUTES.MANUALFINDSPECIALIST}
            component={FindManualFindSpecialist}
        />
        <Route path={ROUTES.FORMSUCCESS} component={FormSuccess}/>
        <Route path={ROUTES.NOT_FOUND} component={NotFount}/>
        <Route path='*'>
            <Redirect to={ROUTES.NOT_FOUND}/>
        </Route>
    </Switch>
);

export default Routes;
