import {Button} from "../../components/Button";
import React, {useRef} from "react";

import heroSrc from '../../assets/images/monument_hero.png';
import img1 from '../../assets/images/monument_img_1.png';
import img2 from '../../assets/images/monument_img_2.png';

import styles from './Reminder.module.scss'
import Menu from "../../components/Menu";
import Cta from "../../components/HomePage/Cta";
import Footer from "../../components/Footer";


function Reminder() {
    const sectionRef = useRef(null);

    const scrollToSection = () => {
        // @ts-ignore
        sectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return <>
        <Menu />
        <section className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.hero}>
                    <div className={styles.left}>
                        <h1>
                            Пам'ятка<br/>
                            онлайн<br/>
                            консультації
                        </h1>
                        <Button onClick={scrollToSection} buttonStyles={styles.button}>
                            Читати
                        </Button>
                    </div>
                    <img className={styles.image} src={heroSrc} alt='hero'/>
                </div>
            </div>
            <img className={styles.imageSection} ref={sectionRef} src={img1} alt="details"/>
            <img className={styles.imageSection} src={img2} alt="details"/>
            <Cta/>
        </section>
        <Footer/>
    </>

}


export default Reminder