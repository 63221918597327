import { IApi } from '../../api';

export const prefix = 'expert';

export const CONTACT_EXPERT_REQUEST = `${prefix}/CONTACT_EXPERT_REQUEST`;
export const CONTACT_EXPERT_SUCCESS = `${prefix}/CONTACT_EXPERT_SUCCESS`;
export const CONTACT_EXPERT_FAILURE = `${prefix}/CONTACT_EXPERT_FAILURE`;
export const RESET_SUCCESS_STATE = `${prefix}/RESET_SUCCESS_STATE`;

export const connectExpert = (
  data: {
    name: string,
    email: string,
    phone: string,
    connectType?: string,
    cookie: boolean,
    comment?: string,
    expertName: string,
    expertId: string,
  }
) => {
  return {
    type: [
      CONTACT_EXPERT_REQUEST,
      CONTACT_EXPERT_SUCCESS,
      CONTACT_EXPERT_FAILURE,
    ],
    promise: (api: IApi) => api.users.connectExpert({ ...data }),
  };
};

export const QUESTION_EXPERT_REQUEST = `${prefix}/QUESTION_EXPERT_REQUEST`;
export const QUESTION_EXPERT_SUCCESS = `${prefix}/QUESTION_EXPERT_SUCCESS`;
export const QUESTION_EXPERT_FAILURE = `${prefix}/QUESTION_EXPERT_FAILURE`;

export const askExpert = (data: any) => {
  return {
    type: [QUESTION_EXPERT_REQUEST, QUESTION_EXPERT_SUCCESS, QUESTION_EXPERT_FAILURE],
    promise: (api: IApi) => api.users.question({ ...data }),
  };
};

export const GET_ALL_EXPERTS_REQUEST = `${prefix}/GET_ALL_EXPERTS_REQUEST`;
export const GET_ALL_EXPERTS_SUCCESS = `${prefix}/GET_ALL_EXPERTS_SUCCESS`;
export const GET_ALL_EXPERTS_FAILURE = `${prefix}/GET_ALL_EXPERTS_FAILURE`;

export const getList = (limit?:number, theme?:string) => {
  return {
    type: [
      GET_ALL_EXPERTS_REQUEST,
      GET_ALL_EXPERTS_SUCCESS,
      GET_ALL_EXPERTS_FAILURE,
    ],
    promise: (api: IApi) => api.experts.list({limit, theme}),
  };
};

export const GET_EXPERTS_BY_IDS_REQUEST = `${prefix}/GET_EXPERTS_BY_IDS_REQUEST`;
export const GET_EXPERTS_BY_IDS_SUCCESS = `${prefix}/GET_EXPERTS_BY_IDS_SUCCESS`;
export const GET_EXPERTS_BY_IDS_FAILURE = `${prefix}/GET_EXPERTS_BY_IDS_FAILURE`;


export const getExpertsByIds = (
    data: any
  ) => {
  return {
    type: [
      GET_EXPERTS_BY_IDS_REQUEST,
      GET_EXPERTS_BY_IDS_SUCCESS,
      GET_EXPERTS_BY_IDS_FAILURE,
    ],
    promise: (api: IApi) => api.experts.listByIds({ data }),
  };
};

export const GET_EXPERT_REQUEST = `${prefix}/GET_EXPERT_REQUEST`;
export const GET_EXPERT_SUCCESS = `${prefix}/GET_EXPERT_SUCCESS`;
export const GET_EXPERT_FAILURE = `${prefix}/GET_EXPERT_FAILURE`;

export const getExpert = (expertId: string) => {
  return {
    type: [GET_EXPERT_REQUEST, GET_EXPERT_SUCCESS, GET_EXPERT_FAILURE],
    promise: (api: IApi) => api.experts.getExpert(expertId),
  };
};
