import React, { Component } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import InputField from '../../../Input';
import Form from '../../Form';
import validate from './validate';

import classnames from './Type.module.scss'


interface IProps {

};

class Type extends Component<InjectedFormProps<any, IProps> & IProps> {
  render() {
    const { handleSubmit, valid } = this.props;

    return (
      <Form
        name='Підбір психолога'
        title='Оберіть формат зустрічей з психологом'
        subtitle='Будь ласка, оберіть один із варіантів'
        valid={valid}
        onSubmit={handleSubmit}
        {...this.props}
      >
        <Field
          name='type'
          component={InputField}
          labelStyles={classnames.typeLabel}
          type='radio'
          value='0'
          label='Особисто та онлайн'
          size='big'
          inCard
        />
        <Field
          name='type'
          component={InputField}
          labelStyles={classnames.typeLabel}
          type='radio'
          value='1'
          label='Тільки онлайн'
          size='big'
          inCard
        />
        <Field
          name='type'
          component={InputField}
          labelStyles={classnames.typeLabel}
          type='radio'
          value='2'
          label='Тільки особисто'
          size='big'
          inCard
        />
      </Form>
    );
  }
  
};

export default reduxForm<{}, IProps>({
  form: 'findSpecialist',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(Type);