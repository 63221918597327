import React, { Component } from 'react';
import ContactButtons from '../../ContactButtons';
import Accordion from '../../Accordion';
 
import classnames from './Faqs.module.scss';

const faqs = [
  {
    question:
      "Психолог, психотерапевт, психоаналітик, психіатр. В чому різниця? До кого звернутись?",
    answer:
      "Психолог - це спеціаліст в області психології, який займається дослідженням психіки та емоційного стану людини або наданням психологічної допомоги здоровим людям. Для більш якісної та глибокої допомоги психологи можуть отримати додаткову освіту та стати психотерапевтами, розширивши свій арсенал методів роботи з клієнтами. \n Психотерапевт завжди є психологом, а психолог не завжди є психотерапевтом. А психоаналіз - це підвид психотерапії. Зараз є багато підвидів психоаналізу, проте всі вони мають такі спільні риси, як довготривалість та глибинну і кропітку роботу по аналізу та самоаналізу. Засновником психоаналізу є Зігмунд Фрейд. Психіатр - це лікар, який займається лікуванням людей з розладами психічної діяльності. Лише психіатр може ставити медичні діагнози та виписувати ліки.",
  },
  {
    question: "Що таке депресія?",
    answer:
      "Депресія - це клінічний діагноз, зі складним перебігом та проявами, проте ми в повсякденні часто не дуже доречно вживаємо це слово, щоб донести свої почуття, втому, знесилення, стрес. Наші спеціалісти допоможуть Вам розібратись з цим та знайти правильний шлях подолання труднощів, а при підозрі на «справжню» клінічну депресію порадять кваліфікованих лікарів для отримання медикаментозної терапії.",
  },
  {
    question: "Чи буде відрізнятися результат в різних напрямках психотерапії",
    answer:
      "Результат буде залежати від рівня кваліфікації спеціаліста та вашої готовності роботи над собою. Напрями в психології можуть лише впливати на формат роботи чи тривалість, але не на ефективність. Як і в будь-яких стосунках важливим фактором буде довіра до вашого психолога, тому рекомендуємо звернути увагу на рівень комфорту з обраним психологом на перших сесіях.",
  },
  {
    question: "Як вибрати психолога? Навіщо це мені?",
    answer:
      "На нашій платформі представлені 3 варіанти підбору психолога. Перший — за допомогою тесту, в якому ви обираєте критерії пошуку, за якими система буде підбирати спеціаліста. Другий варіант — персональний підбір психолога спеціалістом платформи. Третій — самостійний вибір із представленого списку. Також, у кожного спеціаліста є детальна анкета та відеопрезентація, які вам допоможуть зрозуміти, чи це точно “ваш спеціаліст”.",
  },
  {
    question:
      "Скільки це коштує? Допомога новачка гірша за допомогу досвідченого спеціаліста?",
    answer:
      "Вартість консультацій кожного спеціаліста формується самим спеціалістом. Вона залежить від досвіду, вкладених грошей в освіту, курси, тренінги, супервізії. На нашому сайті також є спеціалісти з незначним досвідом роботи, ціни на їх послуги нижчі. Та це не означає низький рівень компетентності, вони є кваліфікованими спеціалістами з вищою психологічною освітою, в них також є досвід роботи та супервізії, це гарний варіант почати терапію, навіть, при обмежених фінансових умовах.",
  },
  {
    question:
      "Чи можу я спілкуватись онлайн? Це не буде менш ефективно, ніж спілкування особисто?",
    answer:
      "В якому вигляді буде проходити спілкування залежить від домовленості безпосередньо зі спеціалістом. На ефективність консультацій впливає рівень та особистість спеціаліста, а не формат зустрічей. Консультації онлайн є не менш ефективними, ніж зустрічі офлайн. В умовах, наприклад, карантину, віддаленості спеціаліста від клієнта, неможливості очних консультацій - онлайн, це спосіб отримати якісну та своєчасну допомогу.",
  },
  // {
  //   question: "Чому у вас немає відгуків?",
  //   answer:
  //     "У нас молода платформа, яка тільки починає свій шлях. Ми не женемось за відгуками та лайками - ми ретельно відбираємо спеціалістів для надання якісної та професійної допомоги. Проте ми постійно збираємо фідбеки від наших клієнтів, та деякі з них(з дозволу клієнта) ми будемо розміщувати у відповідному розділі.",
  // },
  {
    question: "Хто ваші спеціалісти? Як та де ви знаходите психологів?",
    answer:
      "Наші спеціалісти - це психологи та психотерапевти, яких ми ретельно відбираємо після особистої співбесіди. Всі вони мають вищу психологічну освіту, постійно підвищують свою кваліфікацію, проходять супервізії, вдосконалюють себе. При відборі ми перевіряємо достовірність дипломів та сертифікатів.",
  },
  {
    question: "Чи буде дана мною інформація конфіденційна?",
    answer:
      "При роботі з психологом дуже важливими є відчуття безпеки та довіри. Все, що відбувається під час консультації між психологом та клієнтом залишається конфіденційним! Ви можете розказати близьким, про що ви спілкувалися з психологом, а психолог - ні! Є ситуації, коли психологу треба порадитись з психіатром чи супервізором - тоді він запитує дозвіл клієнта.",
  },
  {
    question:
      "Скільки триває консультація (сесія)? Скільки потрібно консультацій (сесій)?",
    answer:
      "Середня тривалість консультації 45-60 хв, якщо клієнт один. Консультація для пар або консультація для батьків з дітьми може бути продовжена до 1 год 30 хв. Деякі види психотерапії також передбачають подовження тривалості до 1 год 30 хв. Кількість сесій може варіюватись, залежно від запиту та від бажаного результату від 1-2 коуч сесій та до 30-40 психоаналітичних сесій. Середня кількість необхідних сесій 10-12. Великий вплив на це має суть питання, з яким клієнт звертається, бажання клієнта до самопізнання, натхнення робити домашні завдання чи дотримуватись наміченого маршруту, а також напрямки та методи, з якими працює спеціаліст.",
  },
  {
    question: "Що таке супервізія? Хто такий супервізор?",
    answer:
      "Супервізія - це професійна допомога психотерапевту, мета якої - підвищення професіоналізму та ефективності. Психотерапевт звертається до більш досвідчених колег (супервізорів) в ситуаціях, коли в нього виникають деякі труднощі в роботі клієнтом. Супервізія допомагає поглянути на ситуацію з різних сторін для більш якісного її вирішення. Супервізія може проводитись індивідуально та в групах, і вона є анонімною для клієнта, тобто психолог не розкриває особистість клієнта перед колегами.",
  },
  {
    question: "Що означає \"індивідуальна психотерапія\" в профілі психолога?",
    answer: "Індивідуальна психотерапія - це \"психолог для психолога\". Іншими словами, для запобігання професійного вигорання та емоційного перевантаження психотерапевту необхідно проводити консультації з іншим психотерапевтом. У цей час один психотерапевт тимчасово стає клієнтом іншого психотерапевта."
  }
];

type Props = {

};

class Faqs extends Component<Props> {
  render() {
    return (
     <section className={classnames.faqs} id="faq">
       <div className={classnames.header}>
          <h1 className={classnames.title}>Питання - відповідь</h1>
          {/* <p className={classnames.subtitle}>Ми публікуємо відгуки тільки тих, хто захотів публічно поділитися своїми історіями</p> */}
       </div>
       <div className={classnames.accordion}>
         <Accordion items={faqs} />
        </div>
        <p className={classnames.footerText}>Не знайшли відповідь на своє запитання? Напишіть нам!</p>
        <ContactButtons />
     </section>
    );
  }
}

export default Faqs;