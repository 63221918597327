import React, { FunctionComponent, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Popup from 'reactjs-popup';

import classnames from './ChooseExpert.module.scss';
import colors from '../../../assets/styles/colorsToJs.module.scss';

import { ReactComponent as TelegramIcon } from '../../../assets/images/telegram.svg';
import { Button } from '../../Button';
import WindowInnerWidthContext from '../../../contexts/WindowInnerWidth';
import ContactSpecialistForm from '../../Form/ContactExpert';

import { SIZES } from '../../../constants';
import { QUERY } from '../../../constants/query';
import { getPopUpStyleGeneral } from '../../../utils';
import config from "../../../config";

type Props = {
  loadingForm: boolean;
  expertId: string;
  expert?: {
    expertId: string;
    profile: {
      firstName: string;
      photoUrl: string;
    };
  };
  connectExpert: (data: any) => void;
};

const ChooseExpertPopup: FunctionComponent<Props & RouteComponentProps> = ({
  loadingForm,
  expertId,
  expert,
  connectExpert,
  history: {
    location: {
      search,
    },
  },
}) => {
  const width = useContext(WindowInnerWidthContext);

  if (!expert) return null;

  const {
    profile: {
      firstName,
      photoUrl,
    },
  } = expert;

  const isSmall = width <= SIZES.tablet;
  const popUpStyleGeneral = getPopUpStyleGeneral(width);

  const queryReg = new RegExp(QUERY.AFTER_TEST, 'ig');
  const isAfterTest = queryReg.test(search);

  const PopUpStyleForm = {
    backgroundColor: colors.light,
  };

  const PopUpWraperStyle = {
    overflow: 'scroll',
  };

  const onHandleSubmit = (values: any) => {
    const data = {
      ...values,
      expertName: firstName,
      expertId: expertId,
    };

    const saveToLocal = () => {
      const userFields = JSON.parse(window.localStorage.getItem('userInfo')!) || {};
      const userData = {
        ...values,
      };

      if (!isEmpty(userFields)) {
        if (userFields.sex) userData['sex'] = userFields.sex;
        if (userFields.subscribe) userData['subscribe'] = userFields.subscribe;
      }

      window.localStorage.setItem('userInfo', JSON.stringify(userData));
    };

    saveToLocal();
    connectExpert(data);
    window.localStorage.setItem('expertConfirmed' , '1');
  };

  return <Popup
    trigger={<Button buttonStyles={classnames.buttonChoose}>
      <TelegramIcon />
      <span>Записатися</span>
    </Button>}
    position="right center"
    modal
    lockScroll
    overlayStyle={PopUpWraperStyle}
    contentStyle={{ ...popUpStyleGeneral, ...PopUpStyleForm }}
  >
    {close => (
      <>
        <ContactSpecialistForm
          expertName={firstName}
          expertPhoto={`${config.static_host}/${expertId}/${photoUrl}`}
          onSubmit={onHandleSubmit}
          onModalClose={close}
          loadingForm={loadingForm}
          isAfterTest={isAfterTest}
        />
        <Button
          buttonStyles={classNames(
            classnames.closeButton,
            classnames.closeButtonForm,
            { [classnames.closeButtonSmallForm]: isSmall },
          )}
          onClick={close}
        />
      </>
    )}
  </Popup>;
};

export default ChooseExpertPopup;
