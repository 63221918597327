import { FormErrors } from 'redux-form';
import _ from 'lodash';

interface StepType {
  theme: {
    manual: {
      specialistSex: string,
      specialistRequest: string
    }
  }
}

const validate = (values: StepType): FormErrors<StepType> => {
  const errors: FormErrors<StepType> = {};
  
  // if (!_.has(values, 'theme.manual.specialistSex')) {
  //   _.set(errors, "theme.manual.specialistSex", "Required");
  // }
  return errors;
};

export default validate;