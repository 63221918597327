import { combineReducers } from 'redux';
import * as actions from './actions';

export interface IUtilState {
  isAnyModalOpen: boolean;
};

export default combineReducers<IUtilState>({
  isAnyModalOpen(state: boolean = false, action) {
    switch (action.type) {
      case actions.SET_ANY_MODAL_OPEN:
        return action.payload.isOpen;
      default:
        return state;
    }
  },
});
