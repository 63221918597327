import React, { useRef } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/HomePage/Header';
import Tabs from '../../components/HomePage/Tabs';
import About from '../../components/HomePage/About';
import HowItWorks from '../../components/HomePage/HowItWorks';
import Cta from '../../components/HomePage/Cta';
import Faqs from '../../components/HomePage/Faqs';
import Sos from '../../components/HomePage/Sos';
import Footer from '../../components/Footer';
import Meta from '../../components/Meta';

type Props = {};

const HomePage:React.FC<Props> = () => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Meta page="home" />
      <Menu isHomepage ref={ref}/>
      <Header ref={ref}/>
      <Tabs />
      <About />
      <HowItWorks />
      <Cta />
      <Faqs />
      <Sos />
      <Footer />
    </div>
  );
}

export default HomePage;
