import React from 'react';
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import {Button} from "../../components/Button";
import {Link} from "react-router-dom";

import classnames from './NotFound.module.scss';
import {ROUTES} from "../../constants/routes";


export default function NotFound() {

    return <>
        <Menu/>
        <section className={classnames.container}>
            <h1>Сторінка видалена або не існує</h1>
            <Link to={ROUTES.HOME}>
                <Button buttonStyles={classnames.button}>На головну</Button>
            </Link>
        </section>
        <Footer />
    </>

}