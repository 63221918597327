import React from 'react';
import classnames from './InfoBadge.module.scss';

import infoImageSrc from '../../assets/images/info.svg';

const InfoBadge: React.FC<{ customImageSrc?: string }> = ({ customImageSrc, children }) => {
  return (
    <div className={classnames.container}>
      <div className={classnames.imageContainer}>
        <img src={customImageSrc ? customImageSrc : infoImageSrc} alt="info_logo"/>
      </div>
      <p className={classnames.text}>{children}</p>
    </div>
  )
};

export default InfoBadge;