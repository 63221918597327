import React, { useState, useContext } from 'react';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';
import classnames from './ImageGallery.module.scss';

import ImageGalleryItem from './ImageGalleryItem';
import Carousel from '../Carousel';
import WindowInnerWidthContext from '../../contexts/WindowInnerWidth';

import { SIZES } from '../../constants';

const MAX_DOTS_COUNT = 5;

interface IProps {
  images: string[];
}

const ImageGallery: React.FC<IProps> = (props) => {
  function nextImage(index: number, length: number) {
    return (index + 1) % length;
  }

  function prevImage(index: number, length: number) {
    return (index + length - 1) % length;
  }

  const width = useContext(WindowInnerWidthContext);
  const [imageIndex, onIndexChange] = useState(-1);
  const { images } = props;

  const handleOpen = (index:number) => {
    onIndexChange(index)
  };

  const handleClose = () => onIndexChange(-1);

  const handleNext = () => onIndexChange(nextImage(imageIndex, images.length));

  const handlePrev = () => onIndexChange(prevImage(imageIndex, images.length));

  if (!images) return null;

  const isTabletOrMobile = width < SIZES.tablet;

  const imagesLength = images.length,
    mainSrc = images[imageIndex],
    nextSrc = images[nextImage(imageIndex, imagesLength)],
    prevSrc = images[prevImage(imageIndex, imagesLength)];
  
  const imageItems = images.map((item, index) => (
    <ImageGalleryItem
      key={item}
      url={item}
      index={index}
      handleOpen={handleOpen}
    />
  ));

  return (
    <>
    {isTabletOrMobile
      ? <Carousel
        containerClass={classnames.carouselImageContainer}
        sliderClass={classnames.slider}
        itemClass={classnames.carouselImageItem}
        hideDots={images.length > MAX_DOTS_COUNT}
      >
        {imageItems}
      </Carousel>
      : <div className={classnames.gallery}>
        {imageItems}
      </div>
    }
    {imageIndex >= 0 && (
      <Lightbox
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        onCloseRequest={handleClose}
        onMoveNextRequest={handleNext}
        onMovePrevRequest={handlePrev}
        enableZoom={true}
      />
    )}
    </>
  );
};

export default ImageGallery;