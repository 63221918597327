import React, { Component } from 'react';
import AccordionSection from './AccordionSection';

import classnames from './Accordion.module.scss';

type Props = {
  items?: Array<{ question: string, answer: string }>,
};

type State = {
  openSections: {[key: string]: boolean },
}

class Accordion extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { 
      openSections: {},
    };
  }

  componentDidMount() {
    const { items } = this.props;

    const openSections = {};

    items?.forEach((item, i) => {
      const key = i.toString();
      (openSections as any)[key] = false;
    });

    this.setState({ openSections })

  }

  onClick = (key: string) => {
    const { openSections } = this.state;

    const isOpen = !!openSections[key];

    this.setState({
      openSections: {
        [key]: !isOpen
      }
    });
  };

  render() {
    const {
      onClick,
      props: { items },
      state: { openSections },
    } = this;

    return (
      <div className={classnames.accordion}>
        {items?.map((item,i) => {
          const key = i.toString();
          return (
            <AccordionSection
              key={key}
              isOpen={!!openSections[key]}
              label={key}
              onClick={onClick}
            >
              {item}
            </AccordionSection>
          )
        })}
      </div>
    )
  }
}

export default Accordion;