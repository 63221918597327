import Base from './base';

export default class Experts extends Base {
  list(query?: object) {
    return this.apiClient.get('expert', query);
  };

  listByIds(data: object) {
    return this.apiClient.get('expert/byIds', data);
  };

  getExpert(expertId: string) {
    return this.apiClient.get(`expert/${expertId}`);
  };

  findExpertManual(data: object) {
    return this.apiClient.post('expert/findManual', data);
  };

  findExpertAuto(data: object) {
    return this.apiClient.post('expert/findAuto', data);
  }
}
