import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../Button';
import { ROUTES } from '../../../constants/routes';

import classnames from './Header.module.scss';

const texts = [
  'з собою',
  'зі своїми емоціями',
  'зі своїми думками',
  'зі своєю сім’єю',
  'зі своєю половинкою',
  'зі своїм тілом',
  'зі своїм оточенням'
];

const Header = React.forwardRef((props, ref: React.Ref<HTMLDivElement>) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => (count === texts.length - 1 ? 0 : count + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className={classnames.header}>
      <div className={classnames.text}>
        <h1 className={classnames.title}>
          Почни жити в балансі <br />
          <div className={classnames.titleCtaWrapper}>
            {texts.map((t, i) => <span className={`${classnames.titleCta} ${count === i ? classnames.active : ''}`} key={t.replace(/ /g, "")}>{t}</span>)}
          </div></h1>
        <p className={classnames.subtitle}>
          Ми подбаємо про твоє психологічне здоров’я та допоможемо підібрати
          перевіреного психолога
        </p>
        <Link to={ROUTES.FINDSPECIALIST} className={classnames.buttonContainer}>
          <div ref={ref}>
            <Button buttonStyles={classnames.button}>
              <span className={classnames.buttonText}>Знайти психолога</span>
            </Button>
          </div>
        </Link>
      </div>
      <div className={classnames.imageContainer}>
        <figure  className={classnames.image}/>
      </div>
    </section>
  );
});

export default Header;
