import { FormErrors } from 'redux-form';

interface StepType {
  type: string
} 

const validate = (values: StepType): FormErrors<StepType> => {
  const errors: FormErrors<StepType> = {};
  if (!values.type) {
    errors.type = 'Required';
  }
  return errors;
};

export default validate;