import { connect } from 'react-redux';

import { setAnyModalOpen } from '../../../redux/util/actions';
import ShowExpertVideo from './ShowExpertVideo';

const actions = {
  setAnyModalOpen,
};

export default connect(null, actions)(ShowExpertVideo);
