import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../config';

const Meta: React.FC<{page: string}> = ({ page }) => {
  const { metaConfig } = config;
  let meta
  switch (page) {
    case 'home':
      meta = metaConfig.home;
      break
    case 'experts':
      meta = metaConfig.experts;
      break
    default:
      meta = metaConfig.default;
  }

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />

      <meta itemProp="name" content={meta.title} />
      <meta itemProp="description" content={meta.description} />

      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />

      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
    </Helmet>
  )
};

export default Meta;
