// import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
// import { createStructuredSelector } from "reselect";

import { compose } from '../../../utils';
// import { chooseExpert } from "../../../redux/expert/actions";

// import { IRootState } from "../../../redux/reducer";

// import {
//   loading,
//   successfullyRegistered,
//   error,
// } from "../../../redux/expert/selectors";

import ContactExpertForm, {IProps} from './ContactExpertForm';

import validate from "./validate";
import { connect } from "react-redux";

// const selector = createStructuredSelector<IRootState, {}>({
//   loading,
//   signupSuccess: successfullyRegistered,
// });

// const actions = { onSubmit: chooseExpert };

// const reduxConect = connect(selector, actions);

const selector = formValueSelector('chooseExpert');

const formConnect = reduxForm<{}, IProps>({
  form: 'chooseExpert',
  destroyOnUnmount: true,
  validate,
});

const reduxConnect = connect((state) => {
  const isTelegramSelected = selector(state, 'connectType') === 'telegram';

  return {isTelegramSelected};
})

export { ContactExpertForm };

export default compose(formConnect, reduxConnect)(ContactExpertForm);
