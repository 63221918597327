import React, { Component } from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import InputField from '../../../Input';
import Form from '../../Form';
import validate from './validate';

import classnames from './DetailedManual.module.scss';

interface IProps {
  onBack: () => void;
};

class DetailedManual extends Component<InjectedFormProps<any, IProps> & IProps> {
  render() {
    const { handleSubmit, onBack, valid } = this.props;

    return (
      <Form
        name='Підбір психолога'
        // title="Стать фахівця"
        title='Побажання'
        subtitle=''
        valid={valid}
        onSubmit={handleSubmit}
        onBack={onBack}
        {...this.props}
      >
        {/* <div className={classnames.buttonsFields}>
          <Field
            name="theme.manual.specialistSex"
            component={InputField}
            type="radio"
            value="woman"
            label="Жінка"
            inCard
            size="big"
            labelStyles={classnames.field}
          />
          <Field
            name="theme.manual.specialistSex"
            component={InputField}
            type="radio"
            value="man"
            label="Чоловік"
            inCard
            size="big"
            labelStyles={classnames.field}
          />
          <Field
            name="theme.manual.specialistSex"
            component={InputField}
            type="radio"
            value="any"
            label="Не важливо"
            size="big"
            inCard
            labelStyles={classnames.field}
          />
        </div>
        <hr className={classnames.line} /> */}
        <div className={classnames.textContainer}>
          {/* <h3 className={classnames.title}>Побажання</h3> */}
          <div className={classnames.textField}>
            <Field
              name='theme.manual.specialistRequest'
              component={InputField}
              type='textarea'
              value=''
              rows={10}
              label=''
              placeholder='Розкажіть, з яким фахівцем хотіли б працювати: вік, освіта, досвід, напрям терапії, особистісні якості.'
            />
          </div>
        </div>
      </Form>
    );
  }
};

export default reduxForm<{}, IProps>({
  form: 'findSpecialist',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(DetailedManual);