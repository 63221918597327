import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import { IRootState } from '../../redux/reducer';
import {
  connectExpert,
  askExpert,
  getExpert,
} from '../../redux/expert/actions';
import {
  loading,
  error,
  expert,
  loadingForm,
} from '../../redux/expert/selectors';
import Expert from './Expert';

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  loadingForm,
  error,
  expert,
});

const actions = {
  connectExpert,
  askExpert,
  getExpert,
};

export { Expert };

export default connect(selector, actions)(withRouter(Expert));
