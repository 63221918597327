import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IRootState } from '../../redux/reducer';
import { getList } from '../../redux/expert/actions';
import {
  loading,
  successfullyRegistered,
  error,
  experts,
} from '../../redux/expert/selectors';
import Experts from './Experts';

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  successfullyRegistered,
  error,
  experts,
});

const actions = {
  getList,
};

export { Experts };

export default connect(selector, actions)(Experts);
