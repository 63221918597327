import { FormErrors } from 'redux-form';

interface StepType {
  name: string;
  email: string;
  phone: string;
  cookie: string;
  connectType: string;
  telegramName: string;
}

const validate = (values: StepType): FormErrors<StepType> => {
  const errors: FormErrors<StepType> = {};

  if (!values.name) {
    errors.name = 'Будь ласка, заповніть поле';
  }
  if (!values.email) {
    errors.email = "Будь ласка, заповніть поле";
  }
  if (!values.phone) {
    errors.phone = "Будь ласка, заповніть поле";
  }
  if (!values.cookie) {
    errors.cookie = 'Будь ласка, погодьтесь з пунктом';
  }
  if (!values.connectType) {
    errors.connectType = 'Будь ласка, вкажіть спосіб зв’язку'
  }

  if (values.connectType === 'telegram' && !values.telegramName) {
    errors.telegramName = 'Будь ласка, вкажіть telegram username'
  }

  return errors;
};

export default validate;
