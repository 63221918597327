import React from 'react';

export interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    onClick?: () => void;
    buttonStyles?: string | undefined;
    type?: 'button' | 'submit' | 'reset' | undefined;
    disabled?: boolean;
    id?: string;
  };

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    onClick,
    buttonStyles,
    type,
    disabled,
    id,
  } = props;
  return (
    <button
      className={buttonStyles}
      onClick={onClick}
      type={type || 'button'}
      disabled={disabled}
      id={id}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  onClick() {},
};

