const ROUTES = {
  HOME: '/',
  EXPERTS: '/experts',
  FINDEXPERTRESULTS: '/experts/results',
  FINDSPECIALIST: '/find-expert',
  AUTOFINDSPECIALIST: '/find-expert/auto',
  MANUALFINDSPECIALIST: '/find-expert/manual',
  FORMSUCCESS: '/find-expert/success',
  REMINDER: '/reminder',
  NOT_FOUND: '/404'
} as const;

export { ROUTES };
