import React, {useEffect} from 'react';
import Experts from '../Experts';

interface IProps {
  loading: boolean;
  error: string;
  experts: object[];
  getExpertsByIds(expertIds:any): void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function onTabClose() {
  window.localStorage.setItem('expertConfirmed' , '');
  window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
    try {
      const expertConfirmed = !!window.localStorage.getItem('expertConfirmed');
      if (!expertConfirmed) {
        e.preventDefault();
        e.returnValue = 'Оберіть, будь ласка, психолога, щоб він міг сконтактувати з вами.';
        window.localStorage.setItem('expertConfirmed' , '1');
        return 'Оберіть, будь ласка, психолога, щоб він міг сконтактувати з вами.'
      }
    } catch (e) {
      console.log(e);
    }
  });
}

const ExpertResult: React.FC<IProps> = (props) => {
  const { experts, loading, getExpertsByIds } = props;
  const expertIds = JSON.parse(window.localStorage.getItem('findExpert')!) || [];
  useEffect(() => {
    expertIds.data && getExpertsByIds(expertIds.data);
    window.localStorage.setItem('expertConfirmed' , '');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Experts loading={loading} experts={experts} isResult />
  );
};

export default ExpertResult;
