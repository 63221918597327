import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import {Link} from 'react-router-dom';
import {Button} from '../../Button';
import {ROUTES} from '../../../constants/routes';

import ctaImage from '../../../assets/images/ready_to_start_image.png';
import classnames from './Cta.module.scss';
import {QUERY} from "../../../constants/query";


function Cta(props: RouteComponentProps) {

    const {
        history: {
            location: {
                pathname,
                search,
            },
        }
    } = props;

    const isPathExpertResult = pathname === ROUTES.FINDEXPERTRESULTS;
    const queryReg = new RegExp(QUERY.AFTER_TEST, 'ig');
    const isAfterTest = queryReg.test(search) || isPathExpertResult;

    return isAfterTest ? null : (
        <section className={classnames.cta}>
            <div className={classnames.info}>
                <h1 className={classnames.title}>Готові почати?</h1>
                <p className={classnames.subtitle}>Ваш крок до внутрішнього балансу</p>
                <Link to={ROUTES.FINDSPECIALIST} className={classnames.buttonContainer}>
                    <Button
                        buttonStyles={classnames.button}
                    >
                        <span className={classnames.buttonText}>Знайти психолога</span>
                    </Button>
                </Link>
            </div>
            <div className={classnames.imageBox}>
                <img src={ctaImage} alt="" className={classnames.image}/>
            </div>
        </section>
    )
}


export default withRouter(Cta);