import { combineReducers } from 'redux';
import { FormStateMap } from 'redux-form';
import { RouterState } from 'connected-react-router';
import { History } from 'history';

import form from './form/reducer';
import router from './router/reducer';
import findSpecialist, { IFindExpertState } from './findSpecialist/reducer';
import expert, { IExpertState } from './expert/reducer';
import util, { IUtilState } from './util/reducer';

export interface IRootState {
  form: FormStateMap;
  router: RouterState;
  findSpecialist: IFindExpertState;
  expert: IExpertState;
  util: IUtilState;
}

export default (history: History) =>
  combineReducers<IRootState>({
    form,
    router: router(history),
    findSpecialist,
    expert,
    util,
  });