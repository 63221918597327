import { FormErrors } from "redux-form";
import _ from "lodash";

interface StepType {
  theme: {
    manual: {
      request: string
    }
  }
}

const validate = (values: StepType): FormErrors<StepType> => {
  const errors: FormErrors<StepType> = {};

  if (!_.has(values, "theme.manual.request")) {
    _.set(errors, "theme.manual.request", "Будь ласка, заповніть поле");
  }

  return errors;
};

export default validate;
