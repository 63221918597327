import { History } from 'history';
import { Action } from 'redux';
import { put, takeEvery } from 'redux-saga/effects';

import {
  FIND_SPECIALIST_SUCCESS,
  FIND_SPECIALIST_MANUAL_SUCCESS
} from './actions';

import { IApi } from '../../api';
import { ROUTES } from '../../constants/routes';

export function* findSpecialist(history: History, api:IApi) {
  yield takeEvery(FIND_SPECIALIST_SUCCESS, function* (data:any) {
    window.localStorage.removeItem('findExpert');
    window.localStorage.setItem("findExpert", JSON.stringify(data));

    yield history.push(ROUTES.FINDEXPERTRESULTS);
  });
}

export function* findSpecialistManual(history: History, api: IApi) {
  yield takeEvery(FIND_SPECIALIST_MANUAL_SUCCESS, function* (data: any) {
    yield history.push(ROUTES.FORMSUCCESS);
  });
}



