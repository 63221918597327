import React from 'react';
import { Link } from 'react-router-dom';
import classnames from './ProgressBar.module.scss';

import logoSrc from '../../assets/images/logo_main.svg';

interface IProps {
  color: string,
  points: string [],
}

const ProgressBar = ({color, points}:IProps) => {
  return (
    <div className={classnames.container}>
      <div className={classnames.logoContainer}>
        <Link to='/'>
          <div className={classnames.logo}>
            <img src={logoSrc} alt="logo" />
          </div>
        </Link>
      </div>
      {/* <div className={classnames.pointsContainer}>
        {points.map(point => (
          <div>{point}</div>
        ))}
      </div> */}
    </div>
  )
}

export default ProgressBar;