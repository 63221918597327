import React from 'react';
import { useLastLocation } from 'react-router-last-location';
import { Button } from '../../Button';

import classnames from './FormExpert.module.scss';

interface IProps {
  handleSubmit(): void;
  onBack?(): void;
  title?: string;
  submitButtonText?: string;
  photo: string;
  isAfterTest?: boolean;
}

const Form: React.FC<any & IProps> = (props) => {
  const {
    handleSubmit,
    title,
    children,
    valid,
    submitButtonText,
    photo,
    isAfterTest
  } = props;

  const buttonId = isAfterTest ? 'findByAuto' : 'findBySelf';

  return (
    <form onSubmit={handleSubmit} className={classnames.form}>
      <div className={classnames.container}>
        <div className={classnames.photoContainer}>
          <img src={photo} alt='expert' />
        </div>
        <h2 className={classnames.title}>{title}</h2>
        <p className={classnames.subtitle}>
          Будь ласка, введіть ваші контактні дані
        </p>
        <p className={classnames.info}>
          * - вказані поля обов'язкові для заповнення
        </p>
        <div className={classnames.formChildren}>{children}</div>
      </div>
      <Button
        type='submit'
        disabled={!valid}
        buttonStyles={classnames.button}
        id={buttonId}
      >
        <span className={classnames.buttonText} id={buttonId}>
          {submitButtonText}
        </span>
      </Button>
    </form>
  );
};

export default Form;
