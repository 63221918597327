import React, { useState } from 'react';

import classnames from './Spoiler.module.scss';

const Spoiler: React.FC<{title: string}> = ({ title, children }) => {
  const [isOpened, handleOpen] = useState(true);
  return (
    <div className={classnames.container}>
      <div className={`${classnames.header} ${!isOpened && classnames.headerIsOpened}`} onClick={() => handleOpen(!isOpened)}>
        <h4 className={classnames.title}>{title}</h4>
        <span className={`${classnames.sign} ${isOpened && classnames.signIsOpened}`}>&#9660;</span>
      </div>
      <div className={`${classnames.children} ${!isOpened && classnames.childrenIsOpened}`}>
        {children}
      </div>
    </div>
  )
};

export default Spoiler;