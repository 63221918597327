import React, { Component } from 'react';

import classnames from './AccordionSection.module.scss';

type Props = {
  children: { question: string, answer: string },
  isOpen: boolean,
  label: string,
  onClick(key:string): void,
};

class AccordionSection extends Component<Props> {
  onClick = () => {
    const { label } = this.props;
    this.props.onClick(label);
  }

  render() {
    const { isOpen, children: { question, answer }} = this.props;
    return (
      <div className={classnames.acBox} onClick={this.onClick}>
        <div className={`${classnames.question} ${isOpen && classnames.open}`}>
          <p className={classnames.qTitle}>{question}</p>
          <div className={classnames.qSign}>{isOpen ? <span>-</span> : <span>+</span>}</div>
        </div>
        <div className={isOpen ? classnames.patch : classnames.hide}/>
        <div className={`${classnames.answer} ${isOpen ? classnames.show : classnames.hide}`}>{answer}</div>
      </div>
    )
  }
}

export default AccordionSection;