import {ROUTES} from "./routes";

export const MENU_LABELS = [
  {
    title: "Допоможемо вирішити",
    route: "/#findExpert",
  },
  {
    title: "Про нас",
    route: "/#about",
  },
  {
    title: "Питання-відповідь",
    route: "/#faq",
  },
  // {
  //   title: "Психологам",
  //   route: "/",
  // },
  {
    title: "Наші спеціалісти",
    route: "/#experts",
  },
  {
    title: "Пам'ятка онлайн консультації",
    route: ROUTES.REMINDER,
  },
] as const;