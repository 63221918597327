import React, { Component } from 'react';

import classnames from './HowItWorks.module.scss';

const howItWorks = [
  {
    title: 'Перший крок',
    subtitle: 'Ви проходите спеціально розроблений нами тест.',
  },
  {
    title: 'Другий крок',
    subtitle: 'За результатами тесту система підбирає спеціалістів, які відповідають вашому запиту.',
  },
  {
    title: 'Третій крок',
    subtitle: 'Ви обираєте спеціаліста серед запропонованих.',
  },
  {
    title: 'Четвертий крок',
    subtitle: 'Ви домовляєтеся з психологом про перший сеанс.'
  }
];

type Props = {

};

class HowItWorks extends Component<Props> {
  render() {
    return (
      <section className={classnames.howItWorks}>
        <h1 className={classnames.title}>Як це працює?</h1>
        <div className={classnames.steps}>
          <div className={classnames.line} />
          {howItWorks.map((element, i) => (
            <div className={classnames.step} key={element.title}>
              <div className={classnames.circle}>{i + 1}</div>
              <div className={classnames.stepInfo}>
                <h4 className={classnames.stepTitle}>{element.title}</h4>
                <p className={classnames.stepSubtitle}>{element.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default HowItWorks;