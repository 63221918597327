import React from 'react';
import classnames from './ImageGallery.module.scss';

interface IProps {
  url: string;
  handleOpen(index: number): void;
  index: number;
}

const ImageGalleryItem: React.FC<IProps> = (props) => {
  const { handleOpen, index, url } = props;

  return (
    <div
      className={classnames.itemContainer}
      onClick={() => handleOpen(index)}
    >
      <img
        className={classnames.itemImage}
        src={url}
      />
    </div>
  );
};

export default ImageGalleryItem;