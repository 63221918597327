import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IRootState } from '../../redux/reducer';
import { getExpertsByIds } from '../../redux/expert/actions';
import {
  loading,
  error,
  experts,
} from '../../redux/expert/selectors';
import ExpertResult from './ExpertResult';

const selector = createStructuredSelector<IRootState, {}>({
  loading,
  error,
  experts,
});

const actions = {
  getExpertsByIds,
};

export { ExpertResult };

export default connect(selector, actions)(ExpertResult);
