import { SIZES } from './constants';

export function compose(...funcs: ((args?: any) => any)[]) {
  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)));
}


export function normalizePhoneNew(value: string) {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^+\d]/g, "");

  if (onlyNums.length === 0) {
    return "";
  }
  if (onlyNums.length <= 3) {
    return `${onlyNums}`;
  }

  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(
        3,
        6
    )}-${onlyNums.slice(6)}`;
  }

  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(
      3,
      6
  )}-${onlyNums.slice(6, 8)}-${onlyNums.slice(8, 15)}`;

}

export const normalizePhone = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/^\+\d{2}/g, "").replace(/[^\d]/g, "");
  if (onlyNums.length === 0) {
    return "";
  }
  if (onlyNums.length <= 3) {
    return `+38 (${onlyNums}`;
  }
  if (onlyNums.length <= 7) {
    return `+38 (${onlyNums.slice(0, 3)})-${onlyNums.slice(3)}`;
  }
  if (onlyNums.length <= 9) {
    return `+38 (${onlyNums.slice(0, 3)})-${onlyNums.slice(
      3,
      6
    )}-${onlyNums.slice(6)}`;
  }
  return `+38 (${onlyNums.slice(0, 3)})-${onlyNums.slice(
    3,
    6
  )}-${onlyNums.slice(6, 8)}-${onlyNums.slice(8, 10)}`;
};

export const typePrice = ({ online, offline }:{ online?:number, offline?:number }) => 
  online && offline
    ? "Онлайн та особисто"
    : online
      ? "Тільки онлайн"
      : offline
        ? "Тільки особисто"
        : null;

export const renderPrice = ({ online, offline }:{ online?:number, offline?:number }) => 
  online === offline
    ? `${online} грн. `
    : online && offline
      ? `${online} грн. / ${offline} грн. `
      : online
        ? `${online} грн. `
        : offline && `${offline} грн . `;

export const scrollWidthOffset = (el:any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export const getPopUpStyleGeneral = (width: number) => {
  const isSmall = width <= SIZES.tablet;
  const isMiddle = width < SIZES.desktop && width > SIZES.tablet;

  const PopUpStyleGeneral = {
    borderRadius: isSmall ? '0' : '10px',
    padding: 0,
    border: 'none',
    width: '50%',
  };

  if (isSmall) PopUpStyleGeneral.width = '100%';
  if (isMiddle) PopUpStyleGeneral.width = 'calc(100% - 60px)'; //TODO: Check if we can get rid of this calc

  return PopUpStyleGeneral;
}
