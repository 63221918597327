import React from 'react';
import { Link } from 'react-router-dom';

import Menu from '../../components/Menu';
import { Button } from '../../components/Button';
import { ROUTES } from '../../constants/routes';

import imageOne from '../../assets/images/find_spec_1.svg';
import imageTwo from '../../assets/images/find_spec_2.svg';
import imageThree from '../../assets/images/find_spec_3.svg';

import classnames from './FindSpecialist.module.scss';

const content = [
  {
    imageSrc: imageOne,
    title: "За результатами тесту",
    text:
      "Після проходження спеціально розробленого тесту система одразу підбере вам психолога. ",
    additionalText: "(рекомендуємо цей варіант)",
    route: ROUTES.AUTOFINDSPECIALIST,
  },
  // {
  //   imageSrc: imageTwo,
  //   title: "За запитом",
  //   text:
  //     "Ви формуєте свій запит у довільній формі, а ми підберемо вам психолога на його основі.",
  //   route: ROUTES.MANUALFINDSPECIALIST,
  // },
  {
    imageSrc: imageThree,
    title: "Самостійний пошук",
    text:
      "Ви можете самостійно обрати собі психолога з переліку всіх наших спеціалістів.",
    route: ROUTES.EXPERTS,
  },
];

const FindSpecialist = () => {
  return (
    <>
      <Menu />
      <div className={classnames.container}>
        <h2 className={classnames.title}>
          Оберіть, будь ласка, спосіб підбору психолога
        </h2>
        {content.map((item) => (
          <div key={item.route} className={classnames.badgeContainer}>
            <div className={classnames.imageContainer}>
              <img
                src={item.imageSrc}
                alt='logo'
                className={classnames.image}
              />
            </div>
            <div className={classnames.textContainer}>
              <div>
                <h3 className={classnames.contentTitle}>{item.title}</h3>
                <p className={classnames.contentText}>{item.text}</p>
                <p className={classnames.addText}>
                  {item.additionalText}
                </p>
              </div>
              <Link to={item.route} className={classnames.buttonContainer}>
                <Button buttonStyles={classnames.button} onClick={() => null}>
                  <span className={classnames.buttonText}>Почати</span>
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FindSpecialist;
