import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import classnames from './Footer.module.scss';

import logo_src from '../../assets/images/logo_main.svg';
import { CONTACT_LINKS } from '../../constants/contactLinks';
import { scrollWidthOffset } from '../../utils';

const links = [
  {
    // title: 'Клієнтам',
    linksArr: [
      // {
      //   title: "Актуальні теми",
      //   url: "",
      // },
      {
        title: "Питання-відповідь",
        url: "/#faq",
      },
      {
        title: "Політика конфіденційності",
        url: CONTACT_LINKS.privacyPolicy,
        newTab: true,
      },
      // {
      //   title: "Обробка персональних даних",
      //   url: "",
      // },
    ],
  },
  // {
  //   // title: 'Спеціалістам',
  //   linksArr: [
  //     {
  //       title: 'Переваги сервісу',
  //       url: '',
  //     },
  //     {
  //       title: 'Умови співпраці',
  //       url: '',
  //     },
  //     {
  //       title: 'Питання-відповідь',
  //       url: '',
  //     },
  //     {
  //       title: 'Заповнити анкету',
  //       url: '',
  //     },
  //   ]
  // },
  // {
  //   // title: 'Контакти',
  //   linksArr: [
  //     // {
  //     //   title: '+38093 756 12 12',
  //     //   url: '',
  //     // },
  //     // {
  //     //   title: 'Онлайн-чат',
  //     //   url: '',
  //     // },
  //   ],
  // },
  {
    // title: 'Соцмережі',
    linksArr: [
      {
        title: "Facebook",
        url: CONTACT_LINKS.facebook,
        newTab: true,
      },
      {
        title: "Instagram",
        url: CONTACT_LINKS.instagram,
        newTab: true,
      },
      {
        title: CONTACT_LINKS.email.replace("mailto:", ""),
        url: CONTACT_LINKS.email,
        newTab: true,
      },
    ],
  },
];

type Props = {

};

interface Links {
  title: string;
  url: string;
  newTab?: boolean;
}

class Footer extends Component<Props> {
  render() {
    return (
      <section className={classnames.footer}>
        <div className={classnames.logo}>
          <Link to='/'>
            <img src={logo_src} alt='logo' />
          </Link>

          <p className={classnames.mark}>{`© egobalance 2020-${new Date().getFullYear()}`}</p>
        </div>
        <div className={classnames.links}>
          {links.map((element, i) => (
            <div className={classnames.linkItem} key={i}>
              {/* <h4 className={classnames.linkTitle}>{element.title}</h4> */}
              {element.linksArr &&
                (element.linksArr as Array<Links>).map((link: Links) =>
                  link.newTab ? (
                    <a
                      href={link.url}
                      target={link.newTab ? "_blank" : "_self"}
                      className={classnames.linkurl}
                      key={link.title}
                    >
                      {link.title}
                    </a>
                  ) : (
                    <HashLink
                      smooth
                      to={link.url}
                      key={link.title}
                      className={classnames.linkurl}
                      scroll={(el) => scrollWidthOffset(el)}
                    >
                      {link.title}
                    </HashLink>
                  )
                )}
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default Footer;
