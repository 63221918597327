import React from 'react';
import Loader from 'react-loader-spinner';

import classnames from './LoaderCircle.module.scss';

const LoaderCircle: React.FC = () => {
  return (
    <div className={classnames.loader}>
      <Loader type='Circles' color='#FF8448' height={60} width={60} />
    </div>
  );
};

export default LoaderCircle;
