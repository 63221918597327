import { combineReducers } from 'redux';
import * as actions from './actions';

export interface IFindExpertState {
  loading: boolean;
  error: object | null;
  successfullyRegistered: string;
}

export default combineReducers<IFindExpertState>({
  loading(state: boolean = false, action) {
    switch (action.type) {
      case actions.FIND_SPECIALIST_REQUEST:
      case actions.FIND_SPECIALIST_MANUAL_REQUEST:
        return true;
      case actions.FIND_SPECIALIST_SUCCESS:
      case actions.FIND_SPECIALIST_MANUAL_SUCCESS:
      case actions.FIND_SPECIALIST_FAILURE:
      case actions.FIND_SPECIALIST_MANUAL_FAILURE:
        return false;
      default:
        return state;
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.FIND_SPECIALIST_FAILURE:
      case actions.FIND_SPECIALIST_MANUAL_FAILURE:
        return action.error || null;
      default:
        return state;
    }
  },
  successfullyRegistered(state: string = '', action) {
    switch (action.type) {
      case actions.FIND_SPECIALIST_SUCCESS:
      case actions.FIND_SPECIALIST_MANUAL_SUCCESS:
        return action.data;
      case actions.RESET_SUCCESS_STATE:
        return '';
      default:
        return state;
    }
  },
});