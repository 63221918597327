import React from 'react';
import ActionButtons from '../ActionButtons';

import classnames from './Form.module.scss';

interface IProps {
  handleSubmit(): void;
  valid?: boolean;
  onBack?(): void;
  title?: string;
  name?: string;
  subtitle?: string;
  end?:boolean;
  customValid?: boolean;
  useCustomValid?: boolean;
};

const Form: React.FC<any & IProps> = (props) => {
  const {
    handleSubmit,
    name,
    title,
    subtitle,
    children,
    valid,
    onBack,
    end,
    customValid,
    useCustomValid,
  } = props;

  return (
    <form onSubmit={handleSubmit} className={classnames.form}>
      <div className={classnames.container}>
        <h2 className={classnames.name}>{name}</h2>
        <h3 className={classnames.title}>{title}</h3>
        <p className={classnames.subtitle}>{subtitle}</p>
        <div className={classnames.formChildren}>{children}</div>
      </div>
      <ActionButtons valid={useCustomValid ? customValid : valid} onBack={onBack} end={end} />
    </form>
  );
};

export default Form;